import styled from "styled-components";
import iconRemove from "@images/remove-code.svg";
import { Color } from "@styles/constants";

export const Coupon = styled.div`
  padding-bottom: 0;
  height: 26px;
  min-width: 124px;
  display: flex;
  > div {
    margin-bottom: 1rem;
    margin-left: auto;
  }
  .code {
    display: inline-flex;
    padding: 1px 5px 1px 15px;
    background-color: ${Color.blackBrand};
    border-radius: 13px;
    color: ${Color.white};
    font-size: 14px;
    line-height: 18px;

    .disable {
      pointer-events: none;
    }
  }
  .no-remove {
    padding: 0px 10px 0px 10px;
  }

  button {
    overflow: hidden;
    background: url("${iconRemove}") center center no-repeat;
    width: 18px;
    margin-left: 0.5rem;
    text-indent: -9999em;
    background-size: 11px;
  }
`;
