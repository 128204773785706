import * as React from "react";
import { PortableText as PortableTextReact } from "@portabletext/react";
import { PortableTextWrapper } from "@styles/typography";
import { FlattenSimpleInterpolation } from "styled-components";
import { Sanity } from "@models/sanity.model";

const portableTextComponents = {
  marks: {
    link: (content) => (
      <a
        href={content.value?.href}
        target={content.value?.blank ? "_blank" : null}
        rel="noreferrer">
        {content.children}
      </a>
    ),
    internalAnchor: (content) => {
      const anchor = content.value?.anchor;
      return <span id={anchor}>{content.children}</span>;
    },
    manualInternalLink: (content) => {
      const href = `#${content.value?.reference}`;
      return <a href={href}>{content.children}</a>;
    },
  },
};

export interface GlobalPortableTextProps {
  value: Sanity.PortableText;
  style?: FlattenSimpleInterpolation;
  styleMobile?: FlattenSimpleInterpolation;
  styleDesktop?: FlattenSimpleInterpolation;
}

const PortableText = ({ value, style, styleMobile, styleDesktop }: GlobalPortableTextProps) => {
  return (
    <PortableTextWrapper
      $style={style}
      styleMobile={styleMobile || undefined}
      styleDesktop={styleDesktop || undefined}>
      <PortableTextReact value={value} components={portableTextComponents} />
    </PortableTextWrapper>
  );
};

export default PortableText;
