import styled from "styled-components";

export const Promo = styled.div`
  width: 100%;
  position: relative;
  button {
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1rem;
    text-decoration: underline;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  text-decoration: underline;
  text-align: end;
`;
