import ConsentManagerModal from "@components/consent-manager-modal";
import useMediaQuery from "@hooks/useMediaQuery";
import { Device } from "@styles/constants";
import { COMPANY_NAME } from "@utils/constants";
import Link from "next/link";
import * as React from "react";

import * as Styled from "./Footer.styled";
import FooterAccordion from "./FooterAccordion";

import { FooterSchema } from "@models/footer.model";
import { Sanity } from "@models/sanity.model";

import { ReactComponent as FacebookIcon } from "@images/facebook-inverted-light.svg";
import { ReactComponent as InstagramIcon } from "@images/instagram-inverted-light.svg";
import { ReactComponent as TwitterIcon } from "@images/twitter-inverted-light.svg";

export interface FooterProps {
  sanity: FooterSchema;
}

export interface SocialIconsMapInterface {
  [key: string]: any;
}

const Footer: React.FC<FooterProps> = ({ sanity }) => {
  const isDesktop = useMediaQuery(Device.mediumUp);
  const currentYear = new Date().getFullYear();

  const openKustomerChat = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (window.Kustomer !== undefined) {
      window.Kustomer.open();
    }
  };

  const SocialIconsMap: SocialIconsMapInterface = {
    instagram: InstagramIcon,
    twitter: TwitterIcon,
    facebook: FacebookIcon,
  };

  const renderLinks = (item: Sanity.LinkList) => {
    return (
      <>
        {item.links.map((item) => {
          const urlPath = typeof item.url !== "undefined" ? item.url : "/";
          const textOnly = <>{item.sub_heading && <span>{item.sub_heading}</span>}</>;
          const externalLink = (
            <a
              href={item.url}
              target={item.item_class !== "call" ? "_blank" : "_self"}
              className={item.item_class ? item.item_class : undefined}
              rel="noreferrer">
              {item.text}
            </a>
          );
          const internalLink = (
            <Link href={urlPath} className="navLink">
              {item.text}
            </Link>
          );
          return (
            <Styled.LinksListItem role="presentation" key={item._key}>
              {!item.url ? textOnly : <>{item.external_url ? externalLink : internalLink}</>}
              {item.item_class === "live-chat" && (
                <button
                  type="button"
                  onClick={(e) => (item.open_intercom ? openKustomerChat(e) : null)}>
                  {item.text}
                </button>
              )}
            </Styled.LinksListItem>
          );
        })}
      </>
    );
  };

  return (
    <Styled.Footer>
      <div className="grid-container">
        <Styled.Row className="grid-x grid-padding-x">
          <Styled.LogoWrap className="cell medium-5">
            <Styled.Logo>
              <Link href="/">
                <img src={sanity.logo_asset.url} alt={sanity.logo_asset.alt} />
              </Link>
            </Styled.Logo>
          </Styled.LogoWrap>

          <Styled.LinksWrap className="cell medium-7">
            {isDesktop ? (
              <>
                {sanity.menus.map((item) => {
                  const slugHeading = item.heading.replace(/\s+/g, "-");
                  return (
                    <Styled.Links key={item._key}>
                      <Styled.LinksHeading
                        id={`footer-heading-${slugHeading.toLowerCase()}`}
                        role="heading">
                        {item.heading}
                      </Styled.LinksHeading>
                      <Styled.LinksNav
                        role="navigation"
                        aria-labelledby={`footer-heading-${slugHeading.toLowerCase()}`}>
                        <Styled.LinksList role="presentation">
                          {renderLinks(item.link_list)}
                        </Styled.LinksList>
                      </Styled.LinksNav>
                    </Styled.Links>
                  );
                })}
              </>
            ) : (
              <>
                {sanity.menus.map((item) => {
                  const slugHeading = item.heading.replace(/\s+/g, "-");
                  return (
                    <Styled.Links key={item._key}>
                      <FooterAccordion
                        id={`${slugHeading.toLowerCase()}`}
                        label={item.heading}
                        content={renderLinks(item.link_list)}
                      />
                    </Styled.Links>
                  );
                })}
              </>
            )}
          </Styled.LinksWrap>
        </Styled.Row>

        <Styled.Row className="grid-x grid-padding-x align-justify">
          <Styled.Colophon className="cell small-shrink medium-auto small-order-2 medium-order-1">
            <ul>
              {sanity.policy_list.links.map((item) => {
                const urlPath = typeof item.url !== "undefined" ? item.url : "/";
                return (
                  <Styled.ColophonItem key={item._key}>
                    <Link href={urlPath}>{item.text}</Link>
                  </Styled.ColophonItem>
                );
              })}
              {sanity?.consent_fields && (
                <Styled.ColophonItem>
                  <ConsentManagerModal consent_fields={sanity.consent_fields} />
                </Styled.ColophonItem>
              )}
              <Styled.ColophonItem>
                &copy; {COMPANY_NAME} {currentYear}
              </Styled.ColophonItem>
            </ul>
          </Styled.Colophon>

          <Styled.SocialIcons className="cell shrink small-order-1 medium-order-2">
            <ul>
              {sanity.social_list.links.map((item) => {
                const handle = item.text.toLowerCase();
                const Icon = SocialIconsMap[handle];

                return (
                  <Styled.SocialIcon key={item._key}>
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      <span className="visually-hidden">{item.text}</span>
                      {Icon && <Icon aria-hidden="true" />}
                    </a>
                  </Styled.SocialIcon>
                );
              })}
            </ul>
          </Styled.SocialIcons>
        </Styled.Row>
      </div>
    </Styled.Footer>
  );
};

export default Footer;
