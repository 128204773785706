import styled from "styled-components";

import { Device, Color } from "@styles/constants";
import {
  BodyMedium,
  SmallBodyMediumCSS,
  BodyRegular,
  SmallBodyRegular,
  XSmallBodyBold,
  XSmallBodyRegularCSS,
} from "@styles/typography";

import iconInformation from "@images/information-circle.svg";
import InfoIcon from "@images/information-circle--black.svg";

export const Litem = styled.div`
  padding: 10px 0;

  @media ${Device.mediumUp} {
    display: flex;
    align-items: flex-start;
    justify-content: stretch;
    flex-wrap: nowrap;
  }
`;

export const LitemInner = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  flex-wrap: nowrap;
  flex: 0 1 auto;
`;

export const LitemInfo = styled.div`
  flex: 1 1 auto;
`;

export const LitemName = styled(BodyMedium)`
  margin: 0;
  padding: 0 0 5px;
`;

export const LineItemInfo = styled.span`
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  top: 2px;
  margin-left: 0.5rem;
  background: url(${iconInformation}) center no-repeat;
  cursor: pointer;
`;
export const LitemMoreInfo = styled(LineItemInfo)`
  background-image: url(${InfoIcon});
  background-size: cover;
  width: 14px;
  height: 14px;
`;

export const LitemImage = styled.div`
  flex: 0 0 190px;
  height: 160px;
  padding: 0 30px 0 0;
  border-radius: 5px;
  display: none;
  overflow: hidden;
  // background-color: ${Color.grayFill};

  figure {
    background-color: ${Color.grayFill};
    aspect-ratio: 1;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  @media ${Device.mediumUp} {
    display: block;
  }
`;

export const LitemDescription = styled.div`
  ${XSmallBodyRegularCSS}
  margin: 0;

  span {
    display: block;
  }
`;

export const LitemQty = styled.div`
  flex: 0 0 110px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1.5em;
`;

export const LitemQtyLabel = styled(BodyRegular)<{ immutable: boolean; as: string }>`
  padding: 0 10px 0 0;
  color: ${Color.grayBrand};
  line-height: 1;
`;

export const LitemQtyLabelSmall = styled(XSmallBodyBold)`
  padding: 0 7px 2px;
  color: ${Color.grayBrand};
  flex: 0;
`;

// Quantity Select Styles
export const SelectContainer = styled.div`
  border: 1px solid ${Color.blackBrand};
  position: relative;
  color: black;
  min-width: 52px;
  flex: 0 0 52px;
  height: 30px;
  position: relative;

  &::after {
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 2px;
    right: 7px;
    height: 5px;
    width: 5px;
    border-right: 1px solid ${Color.blackBrand};
    border-bottom: 1px solid ${Color.blackBrand};
    pointer-events: none;
    transform: rotate(45deg);
  }
`;

// Quantity Select Styles
export const Select = styled.select`
  ${SmallBodyMediumCSS}
  line-height: 1;
  border: none;
  maximum-scale: 1;
  height: 100%;
  appearance: none;
  background: none;
  box-shadow: none;
  padding: 0 13px 0 8px;
  text-align: center;

  &:focus {
    box-shadow: none;
    border: none;
    outline: 1px dotted ${Color.grayBrand};
    outline-offset: 2px;
  }
`;

export const LitemRemoveBtn = styled(SmallBodyRegular)`
  cursor: pointer;
  margin: 10px 0 0 10px;
  padding: 0;
`;

export const LitemVariantSelect = styled.div`
  flex: 0 0 110px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding-top: 15px;
  margin-left: 0.5em;
`;

export const LitemVariantSelectWrapper = styled.div`
  min-width: 150px;

  div[type="select"] {
    border: 1px solid ${Color.blackBrand};

    label {
      ${SmallBodyMediumCSS};
      color: ${Color.blackBrand};
    }

    &::after {
      content: "";
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 2px;
      right: 7px;
      height: 5px;
      width: 5px;
      border-right: 1px solid ${Color.blackBrand};
      border-bottom: 1px solid ${Color.blackBrand};
      pointer-events: none;
      transform: rotate(45deg);
      background: transparent;
    }
  }

  select {
    ${SmallBodyMediumCSS};
    color: ${Color.blackBrand};
    border: 0;
    height: 100%;
    line-height: 1;
    padding: 0 13px 0 8px;
    background: none;

    &:focus {
      box-shadow: none;
      border: none;
      outline: 1px dotted ${Color.grayBrand};
      outline-offset: 2px;
    }
  }
`;
