import Button from "@components/ui/button";
import { Form, Formik } from "formik";
import * as Styled from "./ConsentForm.styled";
import { CategoryPreferences } from "@segment/consent-manager/types/types";

interface Props {
  initialValues: CategoryPreferences;
  handleSubmit: (newPreferences?: boolean | CategoryPreferences, shouldReload?: boolean) => void;
  handleCancel: () => void;
}

const ConsentForm = ({ initialValues, handleSubmit, handleCancel }: Props) => (
  <div className="consent-form">
    <h5>Consent</h5>
    <Styled.InputGroup>
      <Formik
        initialValues={initialValues}
        onSubmit={(formValues) => {
          handleSubmit(formValues);
        }}>
        {({ values, setFieldValue, isValid }) => {
          return (
            <Form data-testid="privacy-consent-form">
              <div>
                <input
                  data-testid="radio-option-yes"
                  id="non-essential-allow"
                  name="Non-Essential"
                  value="yes"
                  type="radio"
                  defaultChecked={values["Non-Essential"]}
                  onClick={() => setFieldValue("Non-Essential", true)}
                />
                <label htmlFor="consent-allow">Yes</label>
              </div>
              <div>
                <input
                  data-testid="radio-option-no"
                  id="non-essential-no"
                  name="Non-Essential"
                  type="radio"
                  value="no"
                  defaultChecked={!values["Non-Essential"]}
                  onClick={() => {
                    setFieldValue("Non-Essential", false);
                  }}
                />
                <label htmlFor="consent-no">No</label>
              </div>
              <Styled.ButtonGroup>
                <Button
                  type="submit"
                  testId="btn-submit-consent"
                  invert
                  text="Save"
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                  disabled={!isValid}
                />
                <Button testId="btn-cancel-consent" text="Cancel" onClick={handleCancel} />
              </Styled.ButtonGroup>
            </Form>
          );
        }}
      </Formik>
    </Styled.InputGroup>
  </div>
);

export default ConsentForm;
