import { Shopify } from "@models/shopify.model";
/**
 * Buy SDK does not provide access to the 'checkoutUserErrors' returned from
 * the addDiscount mutation when the code cannot or should not be applied.
 *
 * It would be easier to check against a CheckoutErrorCode value in order to
 * identify the appropriate messaging that should be communicated to customers
 * via the CheckoutUserError.message value, but instead we need to check against
 * the entirety of the message if we aren't throwing the error ourselves.
 */
export enum CheckoutErrorCode {
  NOT_APPLICABLE = "NOT_APPLICABLE",
}

export enum CustomErrorMessage {
  DEFAULT = "The coupon code you entered is invalid.",
  NON_EXISTANT = "The coupon code you entered doesn't exist. Please try again.",
  NOT_APPLICABLE = "The coupon code you entered could not be applied.",
}
/**
 * @desc Map Buy SDK CheckoutUserError messages to custom error messages.
 * @param {Shopify.CheckoutUserError} checkoutUserError - The CheckoutUserError object containing the
 *     message to be converted.
 * @returns {CustomErrorMessage} CustomErrorMessage
 */
export const convertErrorMessaging = (checkoutUserError: Shopify.CheckoutUserError) => {
  switch (checkoutUserError.message) {
    case "Discount code Unable to find a valid discount matching the code entered": {
      return CustomErrorMessage.NON_EXISTANT;
    }

    case CheckoutErrorCode.NOT_APPLICABLE: {
      return CustomErrorMessage.NOT_APPLICABLE;
    }

    default: {
      return CustomErrorMessage.DEFAULT;
    }
  }
};
