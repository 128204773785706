import styled from "styled-components";

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonGroup = styled.section`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;
