import * as React from "react";

import {
  SUPPORT_EMAIL,
  PHONE_NUMBER_FORMATTED,
  PHONE_NUMBER,
  SUPPORT_EMAIL_CA,
  PHONE_NUMBER_FORMATTED_CA,
  PHONE_NUMBER_CA,
} from "@utils/constants";

export const haveQuestionsContactText = () => {
  const phoneNumber =
    process.env.NEXT_PUBLIC_COUNTRY_CODE === "CA" ? PHONE_NUMBER_CA : PHONE_NUMBER;
  const phoneNumberFormatted =
    process.env.NEXT_PUBLIC_COUNTRY_CODE === "CA"
      ? PHONE_NUMBER_FORMATTED_CA
      : PHONE_NUMBER_FORMATTED;
  const supportEmail =
    process.env.NEXT_PUBLIC_COUNTRY_CODE === "CA" ? SUPPORT_EMAIL_CA : SUPPORT_EMAIL;

  return (
    <p>
      Questions? Email us at{" "}
      <a href={`mailto:${supportEmail}`} target="_blank" rel="noopener noreferrer">
        {supportEmail}
      </a>{" "}
      or call us at <a href={`tel:+${phoneNumber}`}>{phoneNumberFormatted}</a>
    </p>
  );
};
