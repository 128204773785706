import styled from "styled-components";
import { Color, Device } from "@styles/constants";
import { XSmallBodyMediumCSS } from "@styles/typography";

export const Banner = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: var(--banner-height);
  padding: 6px 0;
  background: ${Color.blackBrand};

  .grid-container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Message = styled.span`
  ${XSmallBodyMediumCSS}
  color: ${Color.white};

  @media ${Device.largeUp} {
    white-space: nowrap;
  }

  a,
  a span {
    color: ${Color.white};
  }
`;
