import { ErrorMessage } from "formik";
import React from "react";
import styled, { css } from "styled-components";

import buttonAffirm from "@images/affirm-button.svg";
import iconCheckbox from "@images/checkmark_asset.svg";
import iconSelect from "@images/dropdown_arrow.svg";
import iconSelectDisabled from "@images/dropdown_arrow_disabled.svg";
import iconQuestion from "@images/question_icon.svg";
import iconSecure from "@images/security_lock.svg";
import { Color, Device, Font } from "@styles/constants";

const labelMixin = css`
  top: 0.25rem;
  left: 1.1rem;
  font-size: 10px;
  text-transform: uppercase;
`;

const inputMixin = css`
  border: 2px solid
    ${(props: StyledInputProps | StyledSelectProps | StyledTextAreaProps) =>
      props.error ? Color.red80 : Color.black40};
  box-shadow: none;
  color: ${Color.black80};
  padding: 1rem 1rem 0.5rem 1rem;
  height: 3rem;
`;
const copyMixin = `
  color: ${Color.black60};
  text-align: ${(p: { align?: string }) => p.align || "left"};
  font-size: ${(p: { size?: string }) =>
    p.size === "small" ? 12 : p.size === "medium" ? 14 : 16}px;
  line-height: ${(p: { size?: string }) =>
    p.size === "small" ? 14 : p.size === "medium" ? 18 : 20}px;
`;
interface CopyProps {
  align?: "left" | "center" | "right" | "justify";
  size?: "small" | "medium";
}

export const P = styled.p<CopyProps>`
  ${copyMixin};
`;

export const Span = styled.span<CopyProps>`
  ${copyMixin};
`;

export const CardElementContainer = styled.div`
  .StripeElement {
    ${inputMixin}
    background: #fff;
    margin-bottom: 1rem;
  }
  .StripeElement--invalid {
    border-color: ${Color.red100};
    color: ${Color.red100};
  }
`;

// I renamed this prop "button type" since 'type' is only used
//  for 'submit' buttons and primary/secondary types are unused
// and TypeScript was throwing errors.
interface ButtonProps {
  buttonType?: "primary" | "secondary";
  width?: string;
  type?: string;
}

export const ButtonStyles = css<ButtonProps>`
  text-align: center;
  cursor: pointer;
  padding: 1.25rem;
  display: inline-block;
  text-transform: uppercase;
  min-width: 16rem;
  text-transform: uppercase;
  transition: all 200ms ease;
  text-decoration: none;
  letter-spacing: 2px;
  font-family: ${Font.semibold};
  font-size: 14px;
  border-radius: 2px;
  background: ${({ buttonType }) => (buttonType === "secondary" ? Color.white : Color.black100)};
  color: ${({ buttonType }) => (buttonType === "secondary" ? Color.black100 : Color.white)};
  border: ${({ buttonType }) =>
    `1px solid ${buttonType === "secondary" ? Color.black100 : Color.white}`};
  &:focus {
    outline: 0;
  }
  &:hover {
    background: ${({ buttonType }) => (buttonType === "secondary" ? Color.white : Color.black80)};
  }
  &:disabled {
    opacity: 0.5;
    background: ${({ buttonType }) => (buttonType === "secondary" ? Color.white : Color.black100)};
    color: ${({ buttonType }) => (buttonType === "secondary" ? Color.black100 : Color.white)};
    border: ${({ buttonType }) =>
      `1px solid ${buttonType === "secondary" ? Color.black100 : Color.white}`};
  }
  @media ${Device.small} {
    width: 100%;
  }
`;

export const Button = styled.button`
  ${ButtonStyles}
`;

export const AffirmButton = styled.button`
  background: url(${buttonAffirm}) center no-repeat;
  border: none;
  text-indent: -999rem;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  background-size: contain;
  height: 60px;
  @media ${Device.small} {
    margin-top: 1.5rem;
    width: 100%;
  }
`;

export const Radio = styled.input`
  border: 1px solid ${Color.black40};
`;

export const Error = styled.div`
  background: ${Color.red20};
`;

export const GlobalError = styled.div`
  padding: 1rem;
  color: ${Color.red100};
  background: ${Color.red20};
  margin-bottom: 1rem;
`;

export const StripeError = () => <GlobalError>Please refresh and try again. If this problem persists, you may need to disable adblock or contact support.</GlobalError>;

interface StyledInputGroupProps {
  type?: "select";
}
const StyledInputGroup = styled.div<StyledInputGroupProps>`
  position: relative;
  margin-bottom: 1rem;
  ${({ type }) => {
    return (
      type === "select" &&
      css`
        &:after {
          content: "";
          position: absolute;
          background: ${Color.black40};
          width: 2px;
          height: 3rem;
          right: 36px;
          top: 0;
        }
        background: ${Color.white};
      `
    );
  }};
`;

const StyledInputGroupTextArea = styled.div<StyledInputGroupProps>`
  position: relative;
  margin-bottom: 1rem;
  background: ${Color.white};
  &:before {
    display: block;
    height: 21px;
    background-color: #fff;
    position: absolute;
    left: 2px;
    right: 2px;
    content: "";
    top: 2px;
  }
`;

const StyledInputLabel = styled.label`
  color: ${Color.black60};
  position: absolute;
  pointer-events: none;
  top: 0.75rem;
  left: 1rem;
  transition: 0.2s ease all;
`;
type StyledInputProps = React.InputHTMLAttributes<HTMLInputElement> & { error?: string } & {
  ref?: React.ForwardedRef<HTMLInputElement>;
};

type StyledSelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & { error?: string };

type StyledTextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & { error?: string };

const StyledInput = styled.input<StyledInputProps>`
  ${inputMixin} margin-bottom: 0;
  &:focus {
    border: 2px solid ${(props) => (props.error ? Color.red80 : Color.black40)};
    box-shadow: none;
    outline: 0;
  }
  &:disabled {
    background: ${Color.black10};
    color: rgba(43, 43, 43, 0.5);
  }

  ${({ value, placeholder }) =>
    (value || placeholder) &&
    `
    ~ label {
      ${labelMixin}
    }
  `} &:focus ~ label {
    ${labelMixin};
  }
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`;

const StyledError = styled.span`
  color: ${Color.red80};
  font-size: 14px;
`;
interface InfoProp {
  icon?: string;
}

const Info = styled.span<InfoProp>`
  width: 16px;
  height: 16px;
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: url(${({ icon }) => (icon === "secure" ? iconSecure : iconQuestion)}) center no-repeat;
`;

interface InputProps extends StyledInputProps {
  label?: string;
  labelFilled?: string;
  icon?: "info" | "secure";
  info?: string;
  name?: string;
}

interface SelectProps extends StyledSelectProps {
  label?: string;
  labelFilled?: string;
  icon?: "info" | "secure";
  info?: string;
  name?: string;
  onChange?: any;
  type?: string;
  omitErrors?: boolean;
  ref?: React.MutableRefObject<HTMLSelectElement>;
}

interface TextAreaProps extends StyledTextAreaProps {
  label?: string;
  labelFilled?: string;
  icon?: "info" | "secure";
  info?: string;
  name?: string;
  value?: string;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
  const { label: labelNormal, labelFilled, icon, info, name, ...rest } = props;
  const label = props.value && labelFilled ? labelFilled : labelNormal;
  return (
    <StyledInputGroup>
      <StyledInput name={name} {...rest} ref={ref} />
      {label && <StyledInputLabel>{label}</StyledInputLabel>}
      {(info || icon) && <Info title={info} icon={icon} />}
      <StyledError>
        <ErrorMessage name={name} />
      </StyledError>
    </StyledInputGroup>
  );
});

// Select

export const StyledSelect = styled.select<StyledSelectProps>`
  ${inputMixin}
  background: url(${iconSelect}) right 10px center no-repeat;
  &:focus {
    border: 2px solid ${(props) => (props.error ? Color.red80 : Color.black40)};
    box-shadow: none;
    outline: 0;
  }
  &:disabled {
    background: ${Color.black10} url(${iconSelectDisabled}) right 10px center no-repeat;
    color: rgba(43, 43, 43, 0.5);
  }

  &:not([multiple]) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  ${({ value }) =>
    value &&
    `
    ~ label {
      ${labelMixin}
      opacity: 0;
    }
  `}

  &:focus ~ label {
    ${labelMixin}
    opacity: 0;
  }
`;

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (props: SelectProps, ref) => {
    const { label, name, omitErrors = false, ...rest } = props;
    return (
      <StyledInputGroup type="select">
        <StyledSelect name={name} {...rest} ref={ref} />
        {label && <StyledInputLabel>{label}</StyledInputLabel>}
        {!omitErrors && (
          <StyledError>
            <ErrorMessage name={name} />
          </StyledError>
        )}
      </StyledInputGroup>
    );
  }
);
Select.displayName = "Select";

const StyledTextarea = styled.textarea<StyledTextAreaProps>`
  margin: 0 0 1rem;
  min-height: 100px;
  ${inputMixin} &:focus {
    border: 2px solid ${(props) => (props.error ? Color.red80 : Color.black40)};
    box-shadow: none;
    outline: 0;
  }
  &:disabled {
    background: ${Color.black10};
    color: ${Color.black20};
  }

  ${({ value }) =>
    value &&
    `
    ~ label {
      ${labelMixin}
    }
  `} &:focus ~ label {
    ${labelMixin};
  }
`;

export const Textarea = (props: TextAreaProps) => {
  const { label: labelNormal, labelFilled, name, ...rest } = props;
  const label = props.value && labelFilled ? labelFilled : labelNormal;
  return (
    <StyledInputGroupTextArea>
      <StyledTextarea name={name} {...rest} />
      {label && <StyledInputLabel>{label}</StyledInputLabel>}
      <StyledError>
        <ErrorMessage name={name} />
      </StyledError>
    </StyledInputGroupTextArea>
  );
};

interface CheckboxProps extends InputProps {
  hasBackground?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  id?: string;
}

interface StyledCheckboxProps extends CheckboxProps {
  type?: string;
}

const StyledCheckbox = styled.input.attrs<StyledCheckboxProps>({
  type: "checkbox",
})`
  appearance: none;
  background: ${Color.black20};
  border: 1px solid ${Color.black80};
  border-radius: 0;
  width: 16px;
  height: 16px;
  margin: 0;
  position: relative;
  &:focus {
    outline: 0;
  }
  &:checked {
    background: ${Color.black100};
    border: 1px solid ${Color.black100};
    &:after {
      content: "";
      background: url(${iconCheckbox}) center center no-repeat;
      color: ${Color.white};
      font-size: 16px;
      position: absolute;
      width: 16px;
      height: 16px;
      top: -1px;
      left: -1px;
    }
  }
`;

interface StyledLabelProps {
  hasBackground: boolean;
}

const StyledLabel = styled.div<StyledLabelProps>`
  display: inline-flex;
  align-items: center;
  background: ${({ hasBackground }) => (!hasBackground ? "transparent" : Color.black10)};
  padding: ${({ hasBackground }) => (hasBackground ? "0.6rem 0" : "0.6rem")};
  label {
    color: ${Color.black80};
  }
  &.gift {
    margin-bottom: 1rem;
  }
  @media ${Device.small} {
    &.gift {
      width: 100%;
    }
  }
`;

export const Checkbox = (props: CheckboxProps) => {
  const { className, hasBackground, label, onClick, ...rest } = props;
  return (
    <StyledLabel className={className} onClick={onClick} hasBackground={hasBackground}>
      <StyledCheckbox {...rest} />
      <label>{label}</label>
    </StyledLabel>
  );
};
