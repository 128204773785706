import { FastBundle } from "@models/fastbundle-api.model";
import { useEffect, useState } from "react";

/**
 * Fast Bundle API
 * https://documenter.getpostman.com/view/24055752/2s84LRSXLf#11773786-d8d5-4241-b6d6-8a6ee8e6cee8
 *
 * PARAMS
 *
 * shop - NEXT_PUBLIC_SHOPIFY_ADMIN_URL
 * (required) Shop's shopify domain (needs to the admin url)
 *
 * productId - Bundle Id - In Shopify Admin Page URL contain the global ID of a product
 * (required) Id of the product which its bundles will be retrieved
 *
 * lang - en
 * (required)
 *
 * is_preview - false
 * (optional) Set to true to retrieve draft bundles
 *
 */

const useFastBundleAPI = (productId: string) => {
  const [fastBundleProduct, setFastBundleProduct] = useState<FastBundle.Product>(
    {} as FastBundle.Product
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchFastBundleProduct = async () => {
      setIsLoading(true);

      const requestOptions = {
        method: "GET",
      };

      try {
        const response = await fetch(
          `https://api.fastbundle.co/v3/baps/${productId}/?shop=${process.env.NEXT_PUBLIC_SHOPIFY_ADMIN_URL}&lang=en&is_preview=false`,
          requestOptions
        );
        const result = await response.json();
        setFastBundleProduct(result);
      } catch (error) {
        console.error(error);
      }

      setIsLoading(false);
    };

    fetchFastBundleProduct();
  }, [productId]);

  return { fastBundleProduct, isLoading };
};

export default useFastBundleAPI;
