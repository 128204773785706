import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useUserProfile } from "@providers/profile";
import { ReactComponent as CloseIcon } from "@images/close.svg";
import SignIn from "./sign-in";
import ForgotPassword from "./forgot-password";
import {
  LoginProps,
  LoginStateProps,
  defaultLoginState,
  defaultUserVerification,
  UserVerificationProps,
  VerificationStatus,
} from "./Login.model";
import { AccountStatusType } from "@providers/profile/ProfileProvider.model";
import Loading from "@components/ui/loading";
import Verification from "./verification";
import { isValidToken } from "@utils/isValidToken";

const Login = ({ isLoginOpen, handleLoginClose }: LoginProps) => {
  const [loginState, setLoginState] = useState<LoginStateProps>(defaultLoginState);
  const [userVerification, setUserVerification] =
    useState<UserVerificationProps>(defaultUserVerification);
  const {
    verification,
    verifyCode,
    logout,
    dispatch,
    isLoggedIn,
    profileState: { authToken },
  } = useUserProfile();

  const { email, code } = verification;
  const verifyUser = async () => {
    setLoginState({ ...loginState, loading: true });
    try {
      let status = loginState.status;
      const response = await verifyCode(code);

      // Logout any current session
      if (isLoggedIn && authToken.value && !isValidToken(authToken)) {
        logout(dispatch, { redirect: false });
      }

      if (response.has_password) {
        status = AccountStatusType.VALID;
      } else {
        status = AccountStatusType.NEEDS_PASSWORD;
      }

      // Will allow "Verifying" message to show for a least a second. So it doesn't just flash.
      setTimeout(() => {
        setUserVerification({ ...userVerification, status: VerificationStatus.INACTIVE });
        setLoginState({ ...loginState, status: status, loading: false });
      }, 1000);
    } catch (error) {
      setUserVerification({ ...userVerification, status: VerificationStatus.ERROR, error });
      setLoginState({ ...loginState, loading: false });
    }
  };

  useEffect(() => {
    if (code === "" || email === "") return;

    setUserVerification({ ...userVerification, status: VerificationStatus.ACTIVE });
    verifyUser();
  }, []);

  const closeLogin = () => {
    handleLoginClose();
    setLoginState(defaultLoginState);
    setUserVerification(defaultUserVerification);
  };

  return (
    <ReactModal
      isOpen={isLoginOpen}
      onRequestClose={closeLogin}
      contentLabel={"Open Login Modal"}
      overlayClassName={"llls__modal__overlay"}
      className={"llls__modal__content"}>
      <div className="modal-login-container">
        <div className="modal-login">
          <header>
            <button className="close" aria-label="Close Login Modal" onClick={closeLogin}>
              <CloseIcon />
            </button>
          </header>

          <div className="grid-x grid-margin-x">
            {userVerification.status !== VerificationStatus.INACTIVE ? (
              <Verification
                userVerification={userVerification}
                dispatchUserVerification={setUserVerification}
                handleLoginClose={closeLogin}
              />
            ) : loginState.status == AccountStatusType.FORGOT_PASSWORD ? (
              <div className="cell small-12">
                <ForgotPassword email={loginState.active_email} handleLoginClose={closeLogin} />
              </div>
            ) : (
              <div className="cell small-12">
                <SignIn
                  loginState={loginState}
                  setLoginState={setLoginState}
                  handleLoginClose={closeLogin}
                />
              </div>
            )}
          </div>
        </div>
        <Loading visible={loginState.loading} />
      </div>
    </ReactModal>
  );
};

export default Login;
