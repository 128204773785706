import { Color } from "@styles/constants";
import { SmallBodyRegular } from "@styles/typography";
import styled from "styled-components";

export const PromoButton = styled(SmallBodyRegular)`
  cursor: pointer;
  text-decoration: underline;
  color: ${Color.blackBrand};
  margin: 10px 0 0 0;
  padding: 5px 0;
`;

export const PromoFormWrap = styled.div`
  padding: 10px 0 0;

  input {
    border: 1px solid ${Color.blackBrand};

    &:focus {
      border: 1px solid ${Color.grayBrand};
    }
  }
`;
