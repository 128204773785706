import { Color, Device, Ease, FIXED_NAVBAR_HEIGHT } from "@styles/constants";
import { rem } from "@styles/helpers";
import { BodyMediumCSS } from "@styles/typography";
import Link from "next/link";
import React from "react";
import { scroller } from "react-scroll";
import styled, { css } from "styled-components";
import { UrlObject } from "url";

export interface ButtonStylesProps {
  $dark?: boolean;
  disabled?: boolean;
  $invert?: boolean;
}

/**
 * TODO: Use this to refctor the UI Button
 * We need to strip this button component and make
 * Button, LinkButton. Component is over engineered
 */
export const BlackBrandButton = css`
  ${BodyMediumCSS}
  
  display: block;
  padding: 15px 30px;
  max-width: none;
  border-radius: 50px;

  transition: background ${Ease.duration} ${Ease.out}, color ${Ease.duration} ${Ease.out};
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  background: ${Color.blackBrand};
  border: 1px solid ${Color.blackBrand};
  color: ${Color.white};

  &:focus,
  &:hover {
    background: #4d4d4f;
    color: ${Color.white};
`;

export const ButtonStyles = css<ButtonStylesProps>`
  ${BodyMediumCSS}

  display: block;
  padding: 15px 30px;
  max-width: none;
  border-radius: 50px;

  transition: background ${Ease.duration} ${Ease.out}, color ${Ease.duration} ${Ease.out};
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  @media ${Device.mediumUp} {
    display: inline-block;
    text-align: left;
  }

  & + & {
    margin-top: ${rem(10)};

    @media ${Device.mediumUp} {
      margin-top: 0;
      margin-left: ${rem(15)};
    }
  }

  ${({ $dark }) =>
    $dark
      ? css`
          background: ${({ $invert }: { $invert?: boolean }) =>
            $invert ? "transparent" : Color.white};
          border: ${({ $invert }: { $invert?: boolean }) =>
            $invert ? `1px solid ${Color.white}` : "none"};
          color: ${({ $invert }: { $invert?: boolean }) =>
            $invert ? Color.white : Color.blackBrand};

          &:focus,
          &:hover {
            background: ${({ $invert }: { $invert?: boolean }) =>
              $invert ? Color.white : Color.goldAsh};
            color: ${({ $invert }: { $invert?: boolean }) =>
              $invert ? Color.blackBrand : Color.blackBrand};
         
        `
      : css`
          background: ${({ $invert }: { $invert?: boolean }) =>
            $invert ? "transparent" : Color.blackBrand};
          border: ${({ $invert }: { $invert?: boolean }) =>
            $invert ? `1px solid ${Color.blackBrand}` : "none"};
          color: ${({ $invert }: { $invert?: boolean }) =>
            $invert ? Color.blackBrand : Color.white};

          &:focus,
          &:hover {
            background: ${({ $invert }: { $invert?: boolean }) =>
              $invert ? Color.blackBrand : "#4d4d4f"};
            color: ${Color.white};
          }
        `};

  ${({ disabled }) =>
    disabled &&
    css`
      &:disabled {
        background: ${Color.black100};
        color: ${Color.white};

        &:hover {
          background: ${Color.black};
          color: ${Color.white};
        }
      }
    `};
`;

const StyledLink = styled(Link)`
  ${ButtonStyles}
`;
const StyledAnchor = styled("a")`
  ${ButtonStyles}
`;
const StyledBrandButton = styled("button")`
  ${ButtonStyles}
`;

const Button: React.FC<{
  text?: string;
  href?: string | UrlObject;
  invert?: boolean;
  dark?: boolean;
  disabled?: boolean;
  onClick?: (e?: React.SyntheticEvent<HTMLButtonElement>) => void;
  external?: boolean;
  children?: React.ReactNode;
  testId?: string;
  type?: "button" | "submit" | "reset";
  onMouseDown?: (e?: React.SyntheticEvent<HTMLButtonElement>) => void;
}> = ({
  text,
  href,
  invert,
  dark,
  disabled,
  onClick,
  external,
  children,
  testId,
  type,
  ...other
}) => {
  const StyledButton = (
    !external && href ? StyledLink : external && href ? StyledAnchor : StyledBrandButton
  ) as React.ElementType;
  const hasHash = href?.toString().includes("#");

  const scrollToSection = (el: React.MouseEvent<HTMLElement>) => {
    const path = el.currentTarget.getAttribute("href");
    const hash = path?.slice(path.indexOf("#"));
    if (!hash) return;

    if (hash) {
      const section = document.getElementById(hash.replace("#", ""));

      if (section) {
        const yOffset = -FIXED_NAVBAR_HEIGHT;

        scroller.scrollTo(section.id, {
          duration: 1000,
          delay: 0,
          smooth: true,
          offset: yOffset,
        });
      }
    }
  };

  return (
    <StyledButton
      data-testid={testId || `btn-${text}`}
      $invert={invert}
      $dark={dark}
      disabled={disabled}
      onClick={hasHash ? (el: React.MouseEvent<HTMLElement>) => scrollToSection(el) : onClick}
      type={type || "button"}
      {...other}
      {...(href && external ? { href: href } : { href: href })}
      {...(href && external && { target: "_blank" })}>
      {text}
      {children}
    </StyledButton>
  );
};

export default Button;
