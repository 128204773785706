import { createContext } from "react";
import { Device } from "@styles/constants";
import { Sanity } from "@models/sanity.model";
import * as Styled from "./Banner.styled";
import useMediaQuery from "@hooks/useMediaQuery";

export interface BannerProps {
  desktop_message: string;
  mobile_message: string;
  desktop_cta: Sanity.Cta;
  mobile_cta: Sanity.Cta;
  children: React.ReactNode;
  is_visible: boolean;
}

// Allow components deeper in the stack to determine if the banner is open
export const BannerContext = createContext(false);

const Banner: React.FC<BannerProps> = ({ children, ...props }) => {
  const { desktop_message, mobile_message, desktop_cta, mobile_cta, is_visible } = props;
  const isDesktop = useMediaQuery(Device.mediumUp);

  return (
    <BannerContext.Provider value={is_visible}>
      {is_visible ? (
        <Styled.Banner>
          <div className="grid-container">
            <Styled.Message>
              {isDesktop ? desktop_message : mobile_message}
              {desktop_cta && desktop_cta.text && (
                <>
                  &nbsp;
                  <a href={desktop_cta.url}>
                    <span className="show-for-medium">{desktop_cta.text}</span>
                    <span className="show-for-small-only">{mobile_cta.text}</span>
                  </a>
                </>
              )}
            </Styled.Message>

            {children}
          </div>
        </Styled.Banner>
      ) : (
        <></>
      )}
    </BannerContext.Provider>
  );
};

export default Banner;
