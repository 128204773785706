import * as React from "react";
import Collapsible from "react-collapsible";

import * as Styled from "./Footer.styled";

const { useEffect, useRef, useState, useId } = React;

interface FooterAccordionProps {
  id: string;
  label: string;
  content: React.ReactElement | React.ReactElement[];
}

const FooterAccordion: React.FC<FooterAccordionProps> = ({ id, label, content }) => {
  const [accordionOpen, setAccordionOpen] = useState<boolean>(false);
  const accordionRef = useRef<HTMLElement | undefined>(null);
  const contentRef = useRef<HTMLElement | undefined>();
  const genId = useId();

  const accordionId = `accordion-footer-${id}`;
  const footerId = `footer-${id}`;

  // Have to add a11y attributes to accordion content manually
  // since plugin doesn't give access to it

  useEffect(() => {
    if (!accordionRef.current) return;

    contentRef.current = accordionRef.current.querySelector(
      ".Collapsible__contentOuter"
    ) as HTMLElement;

    if (contentRef.current) {
      contentRef.current.id = footerId;
      contentRef.current.dataset.ariaLabelledBy = accordionId;
      contentRef.current.dataset.role = "region";
    }
  }, []);

  return (
    <Styled.FooterAccordion ref={accordionRef}>
      <Collapsible
        contentElementId={`collapsible-content-${genId}`}
        trigger={label}
        triggerTagName="button"
        triggerElementProps={{
          id: accordionId,
          "aria-controls": footerId,
          "aria-expanded": accordionOpen,
        }}
        transitionTime={200}
        easing={"ease-out"}
        onOpening={() => {
          setAccordionOpen(true);
        }}
        onClosing={() => {
          setAccordionOpen(false);
        }}>
        <Styled.LinksNav>
          <Styled.LinksList>{content}</Styled.LinksList>
        </Styled.LinksNav>
      </Collapsible>
    </Styled.FooterAccordion>
  );
};

export default FooterAccordion;
