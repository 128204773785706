import { Color, Device, EaseHeader, Grid, Z } from "@styles/constants";
import { rem } from "@styles/helpers";
import { AbsoluteCoverCSS } from "@styles/layout";
import {
  Heading4CSS,
  SmallBodyMediumCSS,
  XSmallBodyMediumCSS,
  XSmallBodyRegularCSS,
} from "@styles/typography";
import Link from "next/link";
import styled, { css } from "styled-components";

export const Header = styled.header<{ transparent?: boolean }>`
  position: sticky;
  top: 0;
  z-index: ${Z.header};

  ${({ transparent }) =>
    transparent &&
    `
      @media ${Device.largeUp} {
        height: var(--banner-height);
      }
    `}

  @media print {
    display: none;
  }
`;

export const Wrapper = styled.div<{ transparent?: boolean }>`
  position: relative;
  border-bottom: 1px solid ${Color.goldAsh};
  z-index: 1;

  @media ${Device.largeUp} {
    border-bottom: 0;
  }

  &:before {
    ${AbsoluteCoverCSS}
    content: "";
    background-color: ${Color.white};
    transition: opacity ${EaseHeader.duration.transition} ${EaseHeader.curve};
    z-index: -1;

    @media ${Device.largeUp} {
      opacity: 0;
      opacity: ${({ transparent }) => (transparent ? 0 : 1)};
    }
  }
`;

export const Container = styled.div`
  height: var(--header-height);
  display: flex;
  align-items: center;
`;

export const Hamburger = styled.button<{ menuOpen?: boolean }>`
  width: 50px;
  height: 50px;
  margin-left: ${rem(-10)};
  margin-right: ${rem(15)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    display: block;
    height: 2px;
    width: 30px;
    background: ${Color.blackBrand};
    transition: transform ${EaseHeader.duration.hover} ${EaseHeader.curve};

    + span {
      margin-top: ${rem(8)};
    }
  }

  @media ${Device.largeUp} {
    display: none;
  }

  ${({ menuOpen }) =>
    menuOpen &&
    css`
      span {
        transform: translateY(6px) rotate(45deg);
        transform-origin: center;

        + span {
          transform: translateY(-4px) rotate(-45deg);
        }
      }
    `};
`;

export const Nav = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.div`
  margin-right: ${rem(30)};

  a {
    display: flex;
    padding: 0;
    width: 82px;
    min-height: 14px;
    position: relative;
    transition: opacity ${EaseHeader.duration.hover} ${EaseHeader.curve};

    &:hover {
      opacity: 0.5;
    }

    @media ${Device.largeUp} {
      width: 117.5px;
      min-height: 20px;
    }
  }
  img {
    display: block;
  }
`;

export const LogoDark = styled.div<{ transparent?: boolean }>`
  ${AbsoluteCoverCSS}
  opacity: ${({ transparent }) => (transparent ? 0 : 1)};
  transition: opacity ${EaseHeader.duration.transition} ${EaseHeader.curve};
`;

export const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const Item = styled.li<{ transparent?: boolean }>`
  display: none;

  @media ${Device.largeUp} {
    display: block;
  }
  & + & {
    margin-left: ${rem(30)};
  }
  > a {
    ${SmallBodyMediumCSS}
    display: block;
    text-decoration: none;
    color: ${({ transparent }) => (transparent ? Color.white : Color.blackBrand)};
    transition: color ${EaseHeader.duration.transition} ${EaseHeader.curve},
      opacity ${EaseHeader.duration.hover} ${EaseHeader.curve};

    &:focus,
    &:hover {
      color: ${({ transparent }) => (transparent ? Color.white : Color.blackBrand)};
      opacity: 0.5;
    }
  }
`;

export const ItemButton = styled.li<{ transparent?: boolean }>`
  margin-left: ${rem(30)};

  .sign-in {
    ${XSmallBodyMediumCSS}
    height: 35px;
    background-color: ${Color.blackBrand};
    padding: 0 20px;
    color: ${Color.white};
    border-radius: 17.5px;
    cursor: pointer;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    transition: color ${EaseHeader.duration.transition} ${EaseHeader.curve},
      background-color ${EaseHeader.duration.transition} ${EaseHeader.curve};

    @media ${Device.largeUp} {
      ${({ transparent }) =>
        transparent &&
        `
        background-color: ${Color.white};
        color: ${Color.blackBrand};
      `};
    }

    &:focus,
    &:focus-visible,
    &:hover {
      background-color: ${Color.grayBrand};
      color: ${Color.white};
      transition-duration: 0.35s;
    }
  }
`;

const Dropdown = css<{ open?: boolean }>`
  width: 100%;
  height: ${rem(400)};
  position: absolute;
  left: 0;
  top: 100%;
  clip-path: inset(0 0 ${({ open }) => (open ? 0 : rem(400))} 0);
  transition: opacity ${EaseHeader.duration.transition} ${EaseHeader.curve},
    clip-path ${EaseHeader.duration.transition} ${EaseHeader.curve};
`;

export const DropdownBackground = styled.div<{ open?: boolean }>`
  ${Dropdown}
  pointer-events: none;
  background-color: ${Color.white};
  filter: drop-shadow(0 0 40px rgba(0, 0, 0, 0.1));
  opacity: ${({ open }) => (open ? 1 : 0)};
`;

export const DropdownItem = styled.div<{ open?: boolean; active?: boolean }>`
  ${Dropdown}
  opacity: ${({ open, active }) => (open && active ? 1 : 0)};
  z-index: ${({ active }) => (active ? 1 : 0)};
  pointer-events: ${({ active }) => (active ? "all" : "none")};
`;

export const DropdownContainer = styled.div`
  height: ${rem(400)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DropdownText = styled.div`
  max-width: ${rem(480)};
`;

export const DropdownCtas = styled.div`
  margin-top: ${rem(30)};
  display: flex;

  > * + * {
    margin-left: ${rem(16)};
  }
`;

export const DropdownMedia = styled.div`
  --media-width: 42%;
  --media-offset: 1.25rem;
  width: calc(var(--media-width) + var(--media-offset));
  height: 100%;
  flex-shrink: 0;
  margin-left: ${rem(20)};
  margin-right: calc(-1 * var(--media-offset));
  background-color: ${Color.blackBrand};

  @media ${Device.mediumUp} {
    --media-offset: 2.5rem;
  }
  @media ${Device.xlargeUp} {
    --media-offset: (0.625rem + (100vw - 75rem) / 2);
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const MobileMenu = styled.div`
  --offset: calc(var(--banner-height) + var(--header-height) + 1px);
  position: fixed;
  top: var(--offset);
  left: 0;
  height: calc(100vh - var(--offset));
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: ${Z.header};
`;

export const MobileWrapper = styled.div<{ open?: boolean }>`
  max-height: 92%;
  background-color: ${Color.white};
  border-radius: 0 0 12px 12px;
  box-shadow: 0 0 40px rgba(0, 0, 0, ${({ open }) => (open ? 0.1 : 0)});
  padding: 0 ${Grid.mobilePadding} ${Grid.mobilePadding};
  overflow-y: scroll;
  pointer-events: ${({ open }) => (open ? "all" : "none")};
  transform: translateY(${({ open }) => (open ? 0 : "-100%")});
  transition: box-shadow ${EaseHeader.duration.transition} ${EaseHeader.curve},
    transform ${EaseHeader.duration.transition} ${EaseHeader.curve};
`;

export const MobileBungee = styled.div<{ open?: boolean }>`
  transform: scaleY(${({ open }) => (open ? 1 : "1.5")});
  transform-origin: 50% 100%;
  transition: transform ${EaseHeader.duration.transition} ${EaseHeader.curve} 0.1s;
`;

export const MobileHeader = styled.div`
  border-bottom: 1px solid ${Color.goldAsh};
  display: flex;
  justify-content: space-between;
  align-items: center;

  a,
  button {
    ${Heading4CSS}
    font-size: ${rem(18)};
    text-decoration: underline;
    cursor: pointer;

    @media ${Device.mediumUp} {
      font-size: ${rem(18)};
    }
  }
`;

export const CartButton = styled.button<{
  mobile?: boolean;
  transparent?: boolean;
}>`
  position: relative;
  text-align: center;
  cursor: pointer;
  justify-self: flex-end;
  display: flex;
  align-items: center;
  margin: ${({ mobile }) => (mobile ? "0 10px" : 0)};
  transition: opacity ${EaseHeader.duration.hover} ${EaseHeader.curve};

  &:focus,
  &:hover {
    opacity: 0.5;
  }

  color: ${Color.blackBrand};

  svg {
    path {
      &:nth-child(1) {
        stroke: ${Color.blackBrand};

        @media ${Device.largeUp} {
          stroke: ${({ mobile }) => (mobile ? Color.blackBrand : Color.white)};
          transition: stroke ${EaseHeader.duration.hover} ${EaseHeader.curve};
        }
      }

      &:nth-child(2) {
        fill: ${Color.white};
        stroke: ${Color.blackBrand};

        @media ${Device.largeUp} {
          fill: ${Color.blackBrand};
          stroke: ${({ mobile }) => (mobile ? Color.blackBrand : Color.white)};
          transition: fill ${EaseHeader.duration.hover} ${EaseHeader.curve},
            stroke ${EaseHeader.duration.hover} ${EaseHeader.curve};
        }
      }
    }
  }

  ${({ mobile }) =>
    mobile &&
    css`
      font-size: 1rem;
      @media ${Device.largeUp} {
        display: none;
      }
    `};
`;

export const CartQty = styled.span<{ mobile?: boolean; transparent?: boolean }>`
  ${XSmallBodyMediumCSS}

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  color: ${({ mobile }) => (mobile ? Color.blackBrand : Color.white)};
  margin-top: ${({ mobile }) => (mobile ? "1px" : 0)};
  font-size: ${rem(10)};
  line-height: 1;
  transition: color ${EaseHeader.duration.transition} ${EaseHeader.curve};

  @media ${Device.mediumUp} {
    top: 55%;
  }
`;

export const CartIcon = styled.span<{ mobile?: boolean }>`
  position: relative;
  width: ${rem(18)};
  height: ${rem(22)};
  margin-left: ${rem(12)};
`;

export const UtilityLinks = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-end;
  margin-left: ${rem(40)};

  @media ${Device.largeUp} {
    display: flex;
  }
`;

export const UtilityList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const UtilityLink = styled.li<{ transparent?: boolean }>`
  & + & {
    position: relative;
    margin-left: ${rem(13)};
    padding-left: ${rem(13)};

    &::before {
      content: "";
      color: ${Color.white};
      position: absolute;
      margin-top: 1px;
      top: 50%;
      left: 0;
      height: 12px;
      width: 1px;
      background: ${Color.grayBrand};
      transform: translateY(-50%);
    }
  }

  button,
  a {
    ${XSmallBodyMediumCSS}
    font-size: ${rem(16)};
    display: block;
    text-decoration: none;
    cursor: pointer;
    color: ${Color.white};
    transition: opacity ${EaseHeader.duration.hover} ${EaseHeader.curve};

    @media ${Device.mediumUp} {
      font-size: ${rem(14)};
    }

    &:focus,
    &:hover {
      opacity: 0.5;
    }
  }

  ${CartButton} {
    display: flex;
  }
`;

export const MobileList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const MobileItem = styled.li`
  width: 100%;
  padding: ${rem(10)} 0;
  border-bottom: 1px solid ${Color.goldAsh};
`;

export const MobileAnchor = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${Color.blackBrand};

  p {
    color: ${Color.grayBrand};
    margin: 0;
  }
`;

export const MobileMedia = styled.div`
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  margin-right: ${rem(12)};

  img,
  video {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
  }
`;

export const MobileText = styled.div`
  span {
    ${Heading4CSS}

    @media ${Device.mediumUp} {
      font-size: ${rem(20)};
    }
  }
  p {
    ${XSmallBodyRegularCSS}
    font-size: ${rem(14)};

    @media ${Device.mediumUp} {
      font-size: ${rem(14)};
    }
  }
`;

export const MobileSubheading = styled.div`
  ${XSmallBodyMediumCSS}
  font-size: ${rem(14)};
  width: 100%;
  padding-bottom: 10px;
  margin-top: ${rem(30)};
  border-bottom: 1px solid ${Color.goldAsh};
  color: ${Color.grayBrand};
`;

export const MobileCart = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MyAccountLink = styled.div`
  width: 100%;
  padding: ${rem(20)} 0;
  display: flex;
  align-items: center;
  a,
  button {
    color: ${Color.blackBrand};
  }

  span {
    margin: 0 ${rem(8)};
  }
`;

export const MobileSignIn = styled.div`
  padding: ${rem(20)} 0;

  button {
    color: ${Color.blackBrand};
  }
`;

export const SocialNav = styled.div`
  margin-top: ${rem(40)};

  @media ${Device.largeUp} {
    display: none;
  }
`;

export const SocialMediaLinkList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-items: flex-start;
`;

export const SocialMediaLinkItem = styled.li`
  --size: 44px;
  margin-left: ${rem(15)};

  &:first-of-type {
    margin-left: 0;
  }

  & a {
    width: var(--size);
    height: var(--size);
    color: ${Color.blackBrand};
    border: 1px solid ${Color.grayBrand};
    border-radius: 50%;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
  }

  & svg {
    width: 20px;
    height: 20px;
  }
`;

export const OtherLinks = styled.nav`
  ${XSmallBodyMediumCSS}

  @media ${Device.largeUp} {
    display: none;
  }

  margin-top: ${rem(24)};
  display: flex;
  flex-direction: column;
  color: ${Color.grayBrand};

  & > a {
    color: ${Color.grayBrand};
    text-decoration: none;
    margin-bottom: ${rem(10)};
    margin-right: auto;
  }
`;

export const SkipLink = styled.a`
  transform: translateY(-100%);
  transition: transform 0.3s;

  &:focus {
    background: ${Color.blackBrand};
    color: ${Color.white};
    padding: 0.5rem 1rem;
    position: absolute;
    left: 0;
    transform: translateY(0%);
  }

  // visually-hidden
  &:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
  }
`;
