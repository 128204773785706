import { useState, ChangeEvent } from "react";
import parse from "html-react-parser";
import { useShopifyCart } from "@providers/cart/CartProvider";
import * as Styled from "./CartItem.styled";
import { Shopify } from "@models/shopify.model";
import { LineItemSelect } from "@components/cart-overlay/line-item-select";
import CartItemPrice from "../cart-item-price";
import { useUserProfile } from "@providers/profile";

export interface CartItemProps {
  product: Shopify.Product;
  lineItem: Shopify.LineItem;
}

const CartItem = ({ product, lineItem }: CartItemProps) => {
  const { id: lineItemId, title, quantity, variant } = lineItem;
  const { id: variantId, image } = variant;
  const parsedDescription = product.cartDescription
    ? parse(product.cartDescription.value)
    : parse(product.descriptionHtml);
  const { removeItem, updateItem } = useShopifyCart();
  const { profileState: { authState } } = useUserProfile();
  const [loading, setLoading] = useState(false);

  const handleClickRemove = (id: Shopify.LineItemId, variant: Shopify.ProductVariant) => {
    removeItem(authState, id, variant);
  };

  const renderQuantitySelect = () => {
    const handleChange = async (
      e: ChangeEvent<HTMLSelectElement>,
      lineItemId: Shopify.LineItemId
    ) => {
      setLoading(true);

      const quantity = parseInt(e.target.value, 10);
      await updateItem({ id: lineItemId, quantity });

      setLoading(false);
    };

    return (
      <Styled.SelectContainer>
        <Styled.Select
          value={quantity}
          disabled={loading}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            handleChange(e, lineItemId);
          }}>
          {Array.from(Array(100).keys()).map((i) => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </Styled.Select>
      </Styled.SelectContainer>
    );
  };

  const renderRemoveButton = () => {
    return (
      <Styled.LitemRemoveBtn
        role="button"
        as="span"
        tabIndex={0}
        onClick={() => {
          handleClickRemove(lineItemId, variant);
        }}>
        Remove
      </Styled.LitemRemoveBtn>
    );
  };

  const handleVariantChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    await updateItem({
      id: lineItemId, // Checkout current LineItem
      quantity,
      variantId: e.target.value as Shopify.ProductVariantId, // Selected variant
    });
  };

  return (
    <Styled.Litem data-testid='cart-item'>
      <Styled.LitemImage>
        {image?.src && (
          <figure>
            <img src={`${image.src}?width=320&height=320&crop=center`} alt={title} />
          </figure>
        )}
      </Styled.LitemImage>
      <Styled.LitemInner>
        <Styled.LitemInfo>
          <Styled.LitemName>
            {title} ({quantity})
          </Styled.LitemName>
          {parsedDescription && (
            <Styled.LitemDescription>{parsedDescription}</Styled.LitemDescription>
          )}
          <CartItemPrice lineItem={lineItem} />
        </Styled.LitemInfo>
        <div>
          <Styled.LitemQty>
            <Styled.LitemQtyLabel immutable={false} as="span">
              Qty:&nbsp;
            </Styled.LitemQtyLabel>
            {renderQuantitySelect()}
            {renderRemoveButton()}
          </Styled.LitemQty>
          <LineItemSelect
            product={product}
            variantId={variantId}
            handleVariantChange={(e: ChangeEvent<HTMLSelectElement>) => handleVariantChange(e)}
          />
        </div>
      </Styled.LitemInner>
    </Styled.Litem>
  );
};

export default CartItem;
