import { AccountStatusType } from "@providers/profile/ProfileProvider.model";
import { useEffect, useRef } from "react";
import { Form, Formik } from "formik";
import { Input } from "@components/primitives/input";
import Button from "@components/ui/button";

import { emailSchema, verificationSchema, loginSchema } from "@utils/validations";

const SignInForm = ({ loginState, setLoginState, handleSubmit }) => {
  const passwordInput = useRef<HTMLInputElement>();

  interface initialValuesForm {
    email: string;
    password: string;
    passwordConfirmation: string;
  }
  const initialValues: initialValuesForm = {
    email: loginState.active_email,
    password: "",
    passwordConfirmation: "",
  };

  // Focus on the password input if the user has a password
  useEffect(() => {
    if (
      (loginState.status == AccountStatusType.NEEDS_PASSWORD ||
        loginState.status == AccountStatusType.VALID) &&
      passwordInput.current
    ) {
      passwordInput.current.focus();
    }
  }, [loginState.status]);

  let validation;

  switch (loginState.status) {
    case AccountStatusType.NEEDS_VERIFICATION:
      validation = emailSchema;
      break;
    case AccountStatusType.NEEDS_PASSWORD:
      validation = verificationSchema;
      break;
    default:
      validation = loginSchema;
      break;
  }

  return (
    <Formik
      initialValues={{ ...initialValues }}
      validationSchema={validation}
      enableReinitialize={true}
      validateOnChange={false}
      onSubmit={handleSubmit}>
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit }) => {
        return (
          <Form noValidate>
            <Input
              label="Email Address"
              type="email"
              name="email"
              autoComplete="email"
              required
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email && touched.email && errors.email}
              value={values.email}
              data-testid="sign-in-modal-email"
            />
            {(loginState.status == AccountStatusType.NEEDS_PASSWORD ||
              loginState.status == AccountStatusType.VALID) && (
              <Input
                label={
                  loginState.status == AccountStatusType.NEEDS_PASSWORD
                    ? "Choose Password"
                    : "Password"
                }
                ref={passwordInput}
                type="password"
                name="password"
                autoComplete="new-password"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password && touched.password && errors.password}
                value={values.password}
                data-testid="sign-in-modal-password"
              />
            )}
            {loginState.status == AccountStatusType.NEEDS_PASSWORD && (
              <Input
                label="Confirm Password"
                type="password"
                name="passwordConfirmation"
                autoComplete="new-password"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors.passwordConfirmation &&
                  touched.passwordConfirmation &&
                  errors.passwordConfirmation
                }
                value={values.passwordConfirmation}
              />
            )}
            {loginState.status != AccountStatusType.PENDING_VALIDATION && (
              <div className="field" data-testid="sign-in-modal-submit">
                <Button type="submit" onClick={() => handleSubmit()} disabled={isSubmitting}>
                  {loginState.button_text}
                </Button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignInForm;
