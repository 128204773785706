import get from "lodash-es/get";
import { formatErrorMessage } from "@utils/formatErrorMessage";
import { Button } from "@components/primitives/input";
import { VerificationStatus } from "../Login.model";
import { VerificationProps, VerificationErrorProps } from "./Verification.model";
import * as Styled from "./Verification.styled";

const VerificationError = ({
  error,
  dispatchUserVerification,
  handleLoginClose,
}: VerificationErrorProps) => {
  const errorCode = get(error, "response.errors.error_code", null);
  return (
    <>
      <h5>Something went wrong</h5>
      <Styled.Error className="error">{formatErrorMessage(error)}</Styled.Error>
      {errorCode == "cannot_verify_without_password" ? (
        <Styled.ErrorButton
          onClick={() => {
            dispatchUserVerification((prevState) => ({
              ...prevState,
              status: VerificationStatus.INACTIVE,
            }));
          }}>
          Return to login
          <br />
          and set password
        </Styled.ErrorButton>
      ) : (
        <Styled.ErrorButton onClick={handleLoginClose}>Okay</Styled.ErrorButton>
      )}
    </>
  );
};

// Verification was success but user is logged in already.
const VerificationSuccess = ({ handleLoginClose }: Partial<VerificationErrorProps>) => (
  <>
    <h5>Your Account Has Been Verified</h5>
    <div className="field">
      <Button type="submit" onClick={handleLoginClose}>
        Okay
      </Button>
    </div>
  </>
);

const Verification = ({
  userVerification,
  dispatchUserVerification,
  handleLoginClose,
}: VerificationProps) => (
  <Styled.Wrapper className="cell small-12">
    {userVerification.status === VerificationStatus.SUCCESS ? (
      <VerificationSuccess handleLoginClose={handleLoginClose} />
    ) : userVerification.status === VerificationStatus.ERROR ? (
      <VerificationError
        error={userVerification.error}
        dispatchUserVerification={dispatchUserVerification}
        handleLoginClose={handleLoginClose}
      />
    ) : (
      <>
        <h5>Verifying</h5>
        <p className="small-text">Please wait</p>
      </>
    )}
  </Styled.Wrapper>
);

export default Verification;
