import { AFFIRM_MINIMUM } from "@utils/constants";

/**
 *
 * Affirm Callout Wiki Page:
 * https://gitlab.com/mirrorfitness/ecommerce-frontend/-/wikis/AffirmCallout-component-based-on-Affirm-service
 */
export type AffirmClassName = "affirm-as-low-as" | "affirm-site-modal" | "affirm-product-modal";
export type AffirmPageType =
  | "homepage"
  | "landing"
  | "search"
  | "category"
  | "product"
  | "cart"
  | "payment"
  | "banner"
  | "marketplace";
export type AffirmType = "text" | "symbol";

export interface AffirmCalloutProps {
  className: AffirmClassName;
  total?: number | string;
  pageType: AffirmPageType;
  affirmType?: AffirmType;
  learnmore?: boolean;
  modalText?: string;
}

const MonthlyPaymentMessaging = ({
  className,
  total,
  pageType,
  affirmType,
  learnmore,
}: AffirmCalloutProps) => {
  return (
    <p
      className={className}
      data-amount={Number(total) * 100}
      data-page-type={pageType}
      data-affirm-type={affirmType}
      data-learnmore-show={learnmore}
      data-testid="affirm-monthly-payment-messaging"
    />
  );
};

const ProductModalButton = ({ className, total, pageType, modalText }: AffirmCalloutProps) => {
  return (
    <button
      className={className}
      data-page-type={pageType}
      data-amount={Number(total) * 100}
      data-testid="affirm-product-modal-button"
    >
      {modalText}
    </button>
  );
};

const SiteModalButton = ({ className, pageType, modalText }: AffirmCalloutProps) => {
  return (
    <button
      className={className}
      data-page-type={pageType}
      data-testid="affirm-site-modal-button"
    >
      {modalText}
    </button>
  );
};

const AffirmCallout = ({
  className,
  total,
  pageType,
  learnmore,
  modalText,
}: AffirmCalloutProps) => {
  if (Number(total) < AFFIRM_MINIMUM) return null;

  const callout: Record<AffirmClassName, JSX.Element> = {
    "affirm-as-low-as": (
      <MonthlyPaymentMessaging
        className="affirm-as-low-as"
        pageType={pageType}
        total={total}
        affirmType="text"
        learnmore={learnmore}
      />
    ),
    "affirm-product-modal": (
      <ProductModalButton
        className="affirm-product-modal"
        pageType={pageType}
        modalText={modalText}
        total={total}
      />
    ),
    "affirm-site-modal": (
      <SiteModalButton className="affirm-site-modal" pageType={pageType} modalText={modalText} />
    ),
  };

  return callout[className];
};

export default AffirmCallout;
