import { FastBundle } from "@models/fastbundle-api.model";

/**
 * @desc Find the fast bundle items with more than 2 variants
 * @param {FastBundle.Item[]} items fast bundle items from the Fast Bundle API.
 * @returns {FastBundle.Item[]}
 */
export const variantsFilterHelper = (items: FastBundle.Item[] = []) => {
  return items.filter((item) => item.variants.length > 1);
};

/**
 *
 * @desc Extract items.options values from the Fast Bundle Api if items has more that 2 variants.
 * @param {FastBundle.Item[]} items fast bundle items from the Fast Bundle API.
 * @returns {string[]} Array of Options Values.
 */
export const setFastBundleOptions = (items: FastBundle.Item[]) => {
  return variantsFilterHelper(items).flatMap(({ options }) =>
    // destructuring the options array to get only string[] of values
    options.flatMap(({ values }) => values)
  );
};

/**
 * @desc Extract fast bundle variants from fastBundleProduct.items.variants array
 * @param {FastBundle.Product} fastBundleProduct fast bundle items from the Fast Bundle API.
 * @returns {FastBundle.Variant[]}
 */
export const getFastBundleVariants = (
  fastBundleProduct: FastBundle.Product
): FastBundle.Variant[] => {
  const items = fastBundleProduct.items;
  return variantsFilterHelper(items).flatMap(({ variants }) =>
    variants.flatMap((variant) => variant)
  );
};

type SelectedOption = {
  value: string;
  position: number;
};
/**
 *
 * @desc Identifies the Fast Bundle variant that represents the selected options.
 * @param {SelectedOption[]} selectedOptions selectedOptions from the ProductConfiguration component
 * @param {FastBundle.Product} fastBundleProduct fast bundle product object from useFastBundleAPI
 * @returns {`gid://shopify/ProductVariant/${string}`} Fast Bundle Product Variant ID
 */
export const deriveFastBundleVariantFromOptions = (
  selectedOptions: SelectedOption[],
  fastBundleProduct: FastBundle.Product
): `gid://shopify/ProductVariant/${string}` => {
  const selectedValues = selectedOptions
    .sort((a, b) => a.position - b.position)
    .map((opt) => opt.value);

  const variants = fastBundleProduct.single_bap_variants;

  /**
   * Find variant ids from selectedValues
   * @example
   * returns ['44000010731749', '44000010764517']
   */
  const variantIds = selectedValues.map((value) => {
    const variant = getFastBundleVariants(fastBundleProduct).find((v) => v.option1 === value);
    return variant ? variant.id.toString() : "";
  });

  /**
   * Find matching variant
   * @example
   * returns ['43999465242853-43999465242853-43999465308389-43999…8485-43999466848485-44000010731749-44000010764517', 44000342704357]
   */
  const variant = Object.keys(variants).find((key) => {
    const [, ...rest] = key.split("-"); // ignore first indexes
    const [secondLast, last] = rest.slice(-2); // get last two indexes
    const [id1, id2] = variantIds;
    return variantIds.length > 1
      ? (secondLast === id1 && last === id2) || (secondLast === id2 && last === id1)
      : last === id1;
  });

  return `gid://shopify/ProductVariant/${variants[variant].toString()}`;
};

/**
 * @desc Find Fast Bundle Variants Keys base on the fastBundleVariantId from the fastBundleProduct.variants obj
 * @param {FastBundle.Variants} variants fast bundle variants object
 * @param {number} fastBundleVariantId fast bundle variants key value from obj
 * @returns {[string, number]} Fast Bundle Variants array [0: "...-44000010731749-44000010764517", 1: 44000342704357]
 */
export const findFastBundleVariantKeys = (
  variants: FastBundle.Variants = {},
  fastBundleVariantId: number
) => {
  return Object.entries(variants).find(([key, value]) => value === fastBundleVariantId);
};

/**
 * @desc Find a match between FastBundle.Variant[] and findFastBundleVariantKeys[0] to get Fast Bundle selected variant
 * @param {FastBundle.Product} fastBundleProduct fast bundle product object from useFastBundleAPI
 * @param {number} fastBundleVariantId fast bundle variants key value from obj
 * @returns {FastBundle.Variant[]}
 */
export const findSelectedVariants = (
  fastBundleProduct: FastBundle.Product,
  fastBundleVariantId: number
): FastBundle.Variant[] => {
  const fastBundleItemsVariants = getFastBundleVariants(fastBundleProduct);

  return fastBundleItemsVariants.reduce((indexes: FastBundle.Variant[], variant) => {
    const combinedVariantsKey = findFastBundleVariantKeys(
      fastBundleProduct.single_bap_variants,
      fastBundleVariantId
    );

    const matchingVariantIndexes = combinedVariantsKey[0]
      .split("-")
      .filter((currentVariant) => variant.id.toString() === currentVariant);

    if (matchingVariantIndexes.length > 1) {
      return [...indexes, ...matchingVariantIndexes.map(() => variant)];
    } else if (matchingVariantIndexes.length === 1) {
      return [...indexes, variant];
    }

    return indexes;
  }, []);
};
