import { BodyMedium } from "@styles/typography";
import styled from "styled-components";

export const LitemPrice = styled(BodyMedium)`
  margin: 0;
  padding: 20px 0 0;

  s {
    display: block;
  }
`;
