import { Shopify } from "@models/shopify.model";
import { formatPrice, getDiscountedPrice } from "@utils/pricing";
import { LitemPrice } from "./CartItemPrice.styled";

export interface CartItemPriceProps {
  lineItem: Shopify.LineItem;
}

const CartItemPrice = ({ lineItem }: CartItemPriceProps) => {
  const basePrice = lineItem.quantity * Number(lineItem.variant.price.amount);
  const discountedPrice = getDiscountedPrice(lineItem);

  if (discountedPrice === basePrice) {
    return <LitemPrice>{formatPrice(basePrice)}</LitemPrice>;
  }

  return (
    <LitemPrice>
      <span>
        <span>{formatPrice(discountedPrice)}</span>
        <s className="strikeout">{formatPrice(basePrice)}</s>
      </span>
    </LitemPrice>
  );
};

export default CartItemPrice;
