import { AuthToken } from "@providers/profile/ProfileProvider.model";
import compareAsc from "date-fns/compareAsc";

/**
 * Check if authToken is valid (hasn't expired).
 *
 * @param authToken { AuthToken } Required authToken from ecomm login.
 * @returns {boolean} Returns true if the "expires" date of the authToken
 * is greater than or equal to right now.
 */
export const isValidToken = (authToken: AuthToken) => {
  if (!authToken.value) throw new Error(`An existing auth token must be provided.`);

  // Expires date could be a string from local storage.
  const expires =
    typeof authToken.expires === "string" ? new Date(authToken.expires) : authToken.expires;

  return compareAsc(expires, Date.now()) >= 0;
};
