import useMediaQuery from "@hooks/useMediaQuery";
import { NavigationSchema } from "@models/navigation.model";
import { Device } from "@styles/constants";
import { isValidToken } from "@utils/isValidToken";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import * as Styled from "./Header.styled";

import { events } from "@providers/analytics/analytics";
import { useShopifyCart } from "@providers/cart/CartProvider";
import { useUserProfile } from "@providers/profile";

import Banner from "@components/banner";
import CloudinaryFormat from "@components/cloudinary";
import Login from "@components/login";

import { ReactComponent as FacebookSVG } from "@images/facebook-outline.svg";
import { ReactComponent as InstagramSVG } from "@images/instagram-outline.svg";
import { ReactComponent as TwitterSVG } from "@images/twitter-outline.svg";
import { Sanity } from "@models/sanity.model";

export interface HeaderProps {
  sanity: NavigationSchema;
  hideBanner?: boolean;
  transparent?: boolean;
}

const Header = ({ sanity, hideBanner = false, transparent = false }: HeaderProps) => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownActiveItem, setDropdownActiveItem] = useState(0);
  const [headerLoginOpen, setHeaderLoginOpen] = useState(false);

  const { asPath } = useRouter();
  const {
    totalItems: cartCount,
    cartVisible,
    setCartVisible,
    getProductFromVariant,
    checkout,
  } = useShopifyCart();
  const {
    isLoggedIn,
    isLoginOpen,
    logout,
    dispatch,
    profileState: { authToken, authState },
  } = useUserProfile();

  const isMobile = useMediaQuery(Device.mediumDown);

  const isTransparent = transparent && !isMobile && !scrolled && !dropdownOpen;

  const showDropdown = (index?: number) => {
    if (index || index === 0) setDropdownActiveItem(index);
    setDropdownOpen(true);
  };

  const hideDropdown = () => {
    setDropdownOpen(false);
  };

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const toggleLogin = () => {
    setHeaderLoginOpen(!headerLoginOpen);
  };

  useEffect(() => {
    document.body.style.overflow = menuOpen && isMobile ? "hidden" : "";

    // Toggle Kustomer Chat button behind mobile nav
    const kustomerChat = document.getElementById("kustomer-ui-sdk-iframe");
    if (kustomerChat && isMobile) {
      if (menuOpen) {
        kustomerChat.style.zIndex = "1000";
      } else {
        // Timeout ensures chat button won't jump on top of nav as it animates closed.
        setTimeout(() => {
          kustomerChat.style.zIndex = "2147483000";
        }, 650);
      }
    }
  }, [isMobile, menuOpen]);

  // Open login modal, if set open within profile provider.
  useEffect(() => {
    if (isLoginOpen) setHeaderLoginOpen(true);
  }, [isLoginOpen]);

  useEffect(() => {
    const onScroll = () => {
      const { scrollY } = window;

      if (scrollY > 100 && !scrolled) {
        setScrolled(true);
      } else if (scrollY === 0 && scrolled) {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrolled]);

  useEffect(() => {
    if (isLoggedIn && authToken.value && !isValidToken(authToken)) {
      logout(dispatch, { redirect: false });
    }
  }, []);

  const openCart = () => {
    setCartVisible(true);
    if (authState?.value) {
      const products = [];
      checkout.lineItems.forEach((item) => {
        const product = getProductFromVariant(item.variant.id);
        products.push(product);
      });
      events.cart.view(authState.value, checkout, products);
    }
  };

  const renderAccountLink = () => {
    if (isLoggedIn) {
      return (
        <>
          <Styled.UtilityLink transparent={isTransparent}>
            <Link href="/account" onClick={closeMenu}>
              {sanity.account_text}
            </Link>
          </Styled.UtilityLink>

          <button className="sign-out" onClick={() => logout(dispatch, { redirect: true })}>
            {sanity.sign_out_text}
          </button>
        </>
      );
    } else {
      return (
        <button
          className="sign-in"
          onClick={(e) => {
            e.preventDefault();
            toggleLogin();
          }}>
          {sanity.account_text}
        </button>
      );
    }
  };

  const renderMobileLinks = (links: Sanity.Links[]) =>
    links.map((link) => (
      <Styled.MobileItem key={link._key}>
        <Styled.MobileAnchor
          href={link.url}
          className={asPath === link.url ? "active" : ""}
          tabIndex={menuOpen ? 0 : -1}
          onClick={closeMenu}>
          <Styled.MobileMedia>
            {link.media.cloudinary?.url && (
              <CloudinaryFormat media={link.media.cloudinary} widthOnScreenValues={100} />
            )}
          </Styled.MobileMedia>
          <Styled.MobileText>
            <span>{link.text}</span>
            <p>{link.body}</p>
          </Styled.MobileText>
        </Styled.MobileAnchor>
      </Styled.MobileItem>
    ));

  const renderSocialMediaLinks = () => {
    const socialLinkList = sanity.social_list.links;

    return (
      <Styled.SocialMediaLinkList>
        {socialLinkList.map((item) => {
          return (
            <Styled.SocialMediaLinkItem key={item._key}>
              <Link
                href={item.url}
                target="_blank"
                rel="noreferrer"
                tabIndex={menuOpen ? 0 : -1}
                onClick={closeMenu}>
                {(() => {
                  switch (item.text) {
                    case "Instagram":
                      return <InstagramSVG />;
                    case "Facebook":
                      return <FacebookSVG />;
                    case "Twitter":
                      return <TwitterSVG />;
                    default:
                      return null;
                  }
                })()}
              </Link>
            </Styled.SocialMediaLinkItem>
          );
        })}
      </Styled.SocialMediaLinkList>
    );
  };

  const currentYear = new Date().getFullYear();
  const policyLinks = sanity.policy_list.links;

  return (
    <Styled.Header transparent={transparent}>
      <Styled.SkipLink href="#content">Skip to content</Styled.SkipLink>

      <Banner
        desktop_message={sanity.desktop_promo_message}
        mobile_message={sanity.mobile_promo_message}
        desktop_cta={sanity.desktop_promo_cta}
        mobile_cta={sanity.mobile_promo_cta}
        is_visible={!hideBanner}>
        {/* {!isMobile && (
          <Styled.UtilityLinks>
            <Styled.UtilityList>
              {renderAccountLink()}
              <Styled.UtilityLink>
                <Styled.CartButton
                  aria-label={`${cartCount} items in your shopping cart`}
                  transparent={transparent}
                  onClick={() => openCart()}>
                  <span>{sanity.cart_text}</span>

                  <Styled.CartIcon>
                    <CartBagSVG />
                    <Styled.CartQty transparent={isTransparent}>
                      {cartCount && cartCount > 0 ? cartCount : 0}
                    </Styled.CartQty>
                  </Styled.CartIcon>
                </Styled.CartButton>
              </Styled.UtilityLink>
            </Styled.UtilityList>
          </Styled.UtilityLinks>
        )} */}
      </Banner>

      <Styled.Wrapper transparent={isTransparent} onMouseLeave={hideDropdown}>
        <Styled.Container className="grid-container">
          {/* <Styled.Hamburger menuOpen={menuOpen} onClick={menuOpen ? closeMenu : openMenu}>
            <span />
            <span />
          </Styled.Hamburger> */}
          <Styled.Nav>
            <Styled.Logo>
              <Link href="/" onFocus={hideDropdown} onMouseEnter={hideDropdown}>
                <img src={sanity.logo_bright.url} alt={sanity.logo_bright.alt} />
                <Styled.LogoDark transparent={isTransparent}>
                  <img src={sanity.logo_dark.url} alt={sanity.logo_dark.alt} />
                </Styled.LogoDark>
              </Link>
            </Styled.Logo>
            {/* <Styled.DropdownBackground open={dropdownOpen} /> */}

            <Styled.List>
              {/* {sanity.desktop_links.links.map((link, index) => (
                <Styled.Item key={link._key} transparent={isTransparent}>
                  <Link
                    href={link.url}
                    onFocus={() => showDropdown(index)}
                    onMouseEnter={() => showDropdown(index)}
                    onClick={hideDropdown}>
                    {link.text}
                  </Link>
                  <Styled.DropdownItem
                    key={index}
                    open={dropdownOpen}
                    active={dropdownActiveItem === index}>
                    <Styled.DropdownContainer className="grid-container">
                      <Styled.DropdownText>
                        <Eyebrow>{link.eyebrow}</Eyebrow>
                        <Heading3>{link.heading}</Heading3>
                        <SmallBodyRegular>{link.body}</SmallBodyRegular>
                        <Styled.DropdownCtas>
                          {link.ctas.map((cta, ctaIndex) => (
                            <ButtonHeader
                              key={ctaIndex}
                              text={cta.text}
                              url={cta.url}
                              onClick={hideDropdown}
                              tabIndex={dropdownActiveItem === index && dropdownOpen ? 0 : -1}
                            />
                          ))}
                        </Styled.DropdownCtas>
                      </Styled.DropdownText>

                      <Styled.DropdownMedia>
                        {link.media.cloudinary?.url && (
                          <CloudinaryFormat
                            media={link.media.cloudinary}
                            widthOnScreenValues={100}
                          />
                        )}
                      </Styled.DropdownMedia>
                    </Styled.DropdownContainer>
                  </Styled.DropdownItem>
                </Styled.Item>
              ))} */}
              {sanity.desktop_prominent_cta?.text && sanity.desktop_prominent_cta?.url && (
                <Styled.ItemButton>
                  {renderAccountLink()}
                  {/* <ButtonHeader
                    text={sanity.desktop_prominent_cta.text}
                    url={sanity.desktop_prominent_cta.url}
                    transparent={isTransparent}
                    onFocus={hideDropdown}
                    onMouseEnter={hideDropdown}
                  /> */}
                </Styled.ItemButton>
              )}
            </Styled.List>
          </Styled.Nav>
        </Styled.Container>
      </Styled.Wrapper>

      {/* {isMobile && (
        <Styled.MobileMenu>
          <Styled.MobileWrapper open={menuOpen}>
            <Styled.MobileBungee open={menuOpen}>
              <Styled.MobileHeader>
                {isLoggedIn ? (
                  <Styled.MyAccountLink>
                    <Link href="/account" tabIndex={menuOpen ? 0 : -1} onClick={closeMenu}>
                      {sanity.account_text}
                    </Link>
                    <span>|</span>
                    <button
                      tabIndex={menuOpen ? 0 : -1}
                      onClick={() => logout(dispatch, { redirect: true })}>
                      {sanity.sign_out_text}
                    </button>
                  </Styled.MyAccountLink>
                ) : (
                  <Styled.MobileSignIn>
                    <button
                      tabIndex={menuOpen ? 0 : -1}
                      onClick={(e) => {
                        e.preventDefault();
                        toggleLogin();
                      }}>
                      {sanity.account_text}
                    </button>
                  </Styled.MobileSignIn>
                )}
                <Styled.CartButton
                  tabIndex={menuOpen ? 0 : -1}
                  aria-label={`${cartCount} items in your shopping cart`}
                  onClick={() => openCart()}
                  mobile>
                  <span>{sanity.cart_text}</span>

                  <Styled.CartIcon mobile>
                    <CartBagSVG />
                    <Styled.CartQty mobile>
                      {cartCount && cartCount > 0 ? cartCount : 0}
                    </Styled.CartQty>
                  </Styled.CartIcon>
                </Styled.CartButton>
              </Styled.MobileHeader>

              <nav>
                {sanity.mobile_links?.links && (
                  <Styled.MobileList>
                    {renderMobileLinks(sanity.mobile_links.links)}
                  </Styled.MobileList>
                )}

                {sanity.mobile_subheading && (
                  <Styled.MobileSubheading>{sanity.mobile_subheading}</Styled.MobileSubheading>
                )}

                {sanity.mobile_submenu_links?.links && (
                  <Styled.MobileList>
                    {renderMobileLinks(sanity.mobile_submenu_links.links)}
                  </Styled.MobileList>
                )}
              </nav>
            </Styled.MobileBungee>

            <Styled.SocialNav>{renderSocialMediaLinks()}</Styled.SocialNav>

            <Styled.OtherLinks>
              {policyLinks.map((link) => (
                <Link key={link._key} href={link.url} tabIndex={menuOpen ? 0 : -1}>
                  {link.text}
                </Link>
              ))}
              <span>
                &copy; {COMPANY_NAME} {currentYear}
              </span>
            </Styled.OtherLinks>
          </Styled.MobileWrapper>
        </Styled.MobileMenu>
      )} */}

      <div id="content" />

      <Login isLoginOpen={headerLoginOpen} handleLoginClose={toggleLogin} />
    </Styled.Header>
  );
};

export default Header;
