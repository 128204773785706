import { useConsentManager } from "@providers/consent/ConsentManagerProvider";
import { SVGImage, StyledPrivacyBanner, TextBanner } from "./ConsentBanner.styled";
import { ReactComponent as CloseWhiteIcon } from "@images/close-white.svg";

const ConsentBanner = () => {
  const { closeModal, openModal, bannerVisited } = useConsentManager();
  return (
    <StyledPrivacyBanner>
      <SVGImage
        data-testid="close-icon"
        onClick={() => {
          closeModal();
          bannerVisited();
        }}>
        <CloseWhiteIcon />
      </SVGImage>
      <TextBanner data-testid={"privacy-message"}>
        {`We use cookies (and other similar technologies) to collect data to improve your experience on our site. By using our website, you՚re agreeing to the collection of data as described in our Website Data Collection Policy`}
      </TextBanner>
      <TextBanner
        data-testid="cta-link"
        onClick={() => {
          openModal();
          bannerVisited();
        }}>
        <a>You can change your preferences at any time.</a>
      </TextBanner>
    </StyledPrivacyBanner>
  );
};

export default ConsentBanner;
