import * as qs from "query-string";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocalStorage } from "react-use";
import FocusLock from "react-focus-lock";
import Router from "next/router";

import affirm from "@services/affirm";
import AffirmCallout from "@components/affirm-callout";
import CartItem from "./cart-item";
import PromoButton from "./promo-button";
import CouponList from "./coupon-list";

import { useShopifyCart } from "@providers/cart/CartProvider";
import { useShopifyProduct } from "@providers/product-provider";
import { useMarketingTokens } from "@providers/marketing-tokens";
import { hardwareSlug } from "@utils/routes";
import { formatPrice } from "@utils/pricing";

import * as Styled from "./CartOverlay.styled";
import { CartWithItemsProps } from "./CartOverlay.model";

import { ReactComponent as CloseIcon } from "@images/icon_close-circular.svg";
import { Shopify } from "@models/shopify.model";
import Loading from "@components/ui/loading/Loading";
import CartItemMembership from "./cart-item-membership";
import { LocalLineItem, LocalStorageKeys } from "@models/storage.model";
import { events } from "@providers/analytics/analytics";
import { useUserProfile } from "@providers/profile";
import { useAnonymousIdWithConsent } from "@utils/segment/hooks/useAnonymousIdWithConsent";
import { createCheckoutQueryObject } from "@utils/shopify/checkout";

export const CartEmpty = () => {
  const { setCartVisible } = useShopifyCart();
  const handleClickCheckoutButton = () => {
    setCartVisible(false);
    Router.push(hardwareSlug);
  };

  return (
    <>
      <Styled.Header>
        <Styled.Heading>Your Cart is empty</Styled.Heading>
      </Styled.Header>

      <Styled.CartModalContent></Styled.CartModalContent>

      <Styled.Footer>
        <Styled.CheckoutButton
          data-testid="check-out"
          onClick={handleClickCheckoutButton}
          as="button">
          Shop Now
        </Styled.CheckoutButton>
      </Styled.Footer>
    </>
  );
};

export const sortLineItems = (
  localLineItems: LocalLineItem[],
  lineItems: Shopify.LineItem[]
): Shopify.LineItem[] => {
  const localItemsById = localLineItems?.reduce<{ [id: Shopify.LineItemId]: LocalLineItem }>(
    (_localItemsById, localItem) => {
      _localItemsById[localItem.id] = localItem;
      return _localItemsById;
    },
    {}
  );

  return Array.from(lineItems).sort((a, b) => {
    if (!localItemsById || Object.entries(localItemsById).length === 0) return 0;
    return localItemsById[a.id].addedAt - localItemsById[b.id].addedAt;
  });
};

export const CartWithItems = ({
  checkout,
  checkoutUrl,
  subtotal,
  localLineItems,
  shippingTotal = "0",
  cartCount,
}: CartWithItemsProps) => {
  const { lineItems } = checkout;
  const { state: productState } = useShopifyProduct();
  const { amount: lineItemsSubtotalPrice } = checkout.lineItemsSubtotalPrice;
  const {
    profileState: { authState },
  } = useUserProfile();
  const { getProductFromVariant } = useShopifyCart();

  const sortedLineItems = sortLineItems(localLineItems, lineItems);

  const checkoutStarted = (url: string) => {
    const products = [];
    checkout.lineItems.forEach((item) => {
      const product = getProductFromVariant(item.variant.id);
      products.push(product);
    });
    events.checkout.started(authState.value, checkout, products);
    window.location.assign(url);
  };

  return (
    <Styled.CartModalInner>
      <Styled.Header>
        <Styled.Heading>Your Cart</Styled.Heading>
      </Styled.Header>

      <Styled.CartModalContent>
        <Styled.Litems>
          {sortedLineItems?.length > 0 &&
            sortedLineItems.map((item: Shopify.LineItem) => {
              const product = productState.products[item?.variant?.product?.handle];
              if (!product) return null;

              const isMembership = product.tags.includes("membership");

              if (isMembership) {
                return <CartItemMembership key={item.id} membership={product} lineItem={item} />;
              } else {
                return <CartItem key={item.id} product={product} lineItem={item} />;
              }
            })}
        </Styled.Litems>

        <Styled.Wrapper>
          <Styled.Row>
            <Styled.RowLabel>Subtotal{cartCount > 0 ? ` (${cartCount})` : ""}</Styled.RowLabel>
            <Styled.RowPrice data-testid="subtotal">
              {Number(lineItemsSubtotalPrice) !== Number(subtotal) && (
                <s className="strikeout">{formatPrice(Number(lineItemsSubtotalPrice))}</s>
              )}
              {formatPrice(Number(subtotal))}
            </Styled.RowPrice>
          </Styled.Row>
          <Styled.Row>
            <Styled.RowLabel>Delivery</Styled.RowLabel>
            <Styled.RowPrice data-testid="shipping-total">
              {formatPrice(Number(shippingTotal))}
            </Styled.RowPrice>
          </Styled.Row>
          <Styled.Row>
            <Styled.RowLabel>Sales Tax</Styled.RowLabel>
            <Styled.RowPrice data-testid="tax-total">Calculated at next step</Styled.RowPrice>
          </Styled.Row>
          <CouponList discountApplications={checkout.discountApplications} />
          <PromoButton />
        </Styled.Wrapper>

        <Styled.Total>
          <Styled.TotalLabel>Estimated Total:</Styled.TotalLabel>
          <Styled.TotalPrice data-testid="savings-total">
            {formatPrice(Number(subtotal))}
          </Styled.TotalPrice>
        </Styled.Total>
      </Styled.CartModalContent>

      <Styled.Footer>
        <Styled.CheckoutButton
          data-testid="check-out"
          as="a"
          rel="noreferrer"
          onClick={() => checkoutStarted(checkoutUrl)}>
          Checkout
        </Styled.CheckoutButton>
        <AffirmCallout
          className="affirm-as-low-as"
          pageType="product"
          total={checkout.subtotalPrice.amount}
          learnmore={true}
        />
      </Styled.Footer>
    </Styled.CartModalInner>
  );
};

const CartOverlay = () => {
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const {
    checkout,
    checkout: { lineItems },
    totalItems,
    cartVisible,
    setCartVisible,
    localLineItems,
  } = useShopifyCart();

  const { getShopifyProduct, dispatch } = useShopifyProduct();
  const marketingTokens = useMarketingTokens();
  const anonymousId = useAnonymousIdWithConsent();
  const [checkoutUrl, setCheckoutURL] = useState(checkout.webUrl);

  const checkoutQuery = useMemo(() => {
    return createCheckoutQueryObject(marketingTokens, anonymousId);
  }, [marketingTokens, anonymousId]);

  useEffect(() => {
    if (cartVisible) {
      setTimeout(() => {
        if (closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, 100);
    }
  }, [cartVisible]);

  useEffect(() => {
    if (!checkout.loaded) {
      return;
    }

    lineItems.forEach((lineItem) => {
      const { handle } = lineItem?.variant?.product || {};

      if (handle) {
        getShopifyProduct(dispatch, handle);
      }
    });
  }, [checkout]);

  useEffect(() => {
    if (!affirm) return;
    affirm.refreshUI();
  });

  useEffect(() => {
    if (!checkout.loaded) {
      return;
    }
    const checkoutBaseUrl = { url: checkout.webUrl, query: checkoutQuery };
    const parsedUrl = qs.stringifyUrl(checkoutBaseUrl);
    setCheckoutURL(parsedUrl);
  }, [checkout, checkoutQuery]);

  return (
    <>
      {cartVisible && <Styled.CartScrim onClick={() => setCartVisible(false)} />}
      <FocusLock>
        <Styled.CartModal visible={cartVisible}>
          <Styled.CloseBtn
            aria-label="Close"
            onClick={() => setCartVisible(false)}
            ref={closeButtonRef}>
            <CloseIcon />
          </Styled.CloseBtn>

          <Styled.CartModalInner>
            {checkout && checkout.lineItems.length > 0 ? (
              <CartWithItems
                checkout={checkout}
                checkoutUrl={checkoutUrl}
                subtotal={checkout.subtotalPrice.amount}
                currency={checkout.subtotalPrice.currencyCode}
                localLineItems={localLineItems}
                cartCount={totalItems}
              />
            ) : (
              <CartEmpty />
            )}
            <Loading visible={!checkout.loaded} inline={true} />
          </Styled.CartModalInner>
        </Styled.CartModal>
      </FocusLock>
    </>
  );
};

export default CartOverlay;
