import React, { ChangeEvent, createRef, RefObject, useRef } from "react";
import { Shopify } from "@models/shopify.model";
import { Select } from "@components/primitives/input";
import * as Styled from "../cart-item/CartItem.styled";
import { getVariantNodes } from "@utils/getVariantNodes";
import { SelectedOption } from "@utils/deriveVariantFromOptions";
import { ProductType } from "./LineItemSelect.model";
import useFastBundleAPI from "@hooks/useFastBundleAPI";
import {
  deriveFastBundleVariantFromOptions,
  findSelectedVariants,
  setFastBundleOptions,
} from "@utils/fastBundleVariants";
import { gidFastBundleProductVariantId } from "@models/fastbundle-api.model";

export interface LineItemSelectProps {
  product: Shopify.Product;
  variantId: Shopify.ProductVariantId;
  handleVariantChange: (e) => void;
}

export interface CartSelectorProps extends LineItemSelectProps {
  variants: Shopify.ProductVariant[];
}

const CartSelector = (productType: ProductType, props: CartSelectorProps) => {
  const { product, variantId, variants, handleVariantChange } = props;
  const storefrontProductNumber = product.id.split("/").pop() as string;
  const { fastBundleProduct } = useFastBundleAPI(storefrontProductNumber);
  const selectRefs = useRef<RefObject<HTMLSelectElement>[]>([]);
  /**
   * Since all selects mount with a populated value, this differs
   * from what happens in ProductConfiguration where we need to
   * wait until a sufficient number of options are selected before
   * deriving a variant.
   *
   * Instead, update the line item anytime one of its selects
   * are changed. Additionally, attempting to memoize the selects values is
   * not possible since the component needs to re-render in order to get
   * the new fast bundle variant ID.
   */
  const onVariantOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedOptions: SelectedOption[] = selectRefs.current.map(
      (ref: React.MutableRefObject<HTMLSelectElement>, index) => ({
        value: ref.current.value,
        position: index,
      })
    );

    const derivedFastBundleVariant = deriveFastBundleVariantFromOptions(
      newSelectedOptions,
      fastBundleProduct
    );
    const derivedFastBundleVariantId = gidFastBundleProductVariantId(derivedFastBundleVariant);

    handleVariantChange({
      ...e,
      target: { value: derivedFastBundleVariantId },
    });
  };

  switch (productType) {
    case ProductType.BUNDLE: {
      const fastBundleVariantId = Number(variantId.split("/").pop());
      const selectedVariants = findSelectedVariants(fastBundleProduct, fastBundleVariantId);
      const fastBundleOptions = setFastBundleOptions(fastBundleProduct.items);

      return (
        <>
          {Array.from(Array(selectedVariants.length).keys()).map((positionIndex) => {
            if (!selectRefs.current[positionIndex]) selectRefs.current[positionIndex] = createRef();
            return (
              <React.Fragment key={positionIndex}>
                <Styled.LitemQtyLabelSmall as="span">Qty:1</Styled.LitemQtyLabelSmall>
                <Select
                  name={`variant-${positionIndex}`}
                  id={`select-${positionIndex}`}
                  ref={selectRefs.current[positionIndex]}
                  value={selectedVariants[positionIndex].option1}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => onVariantOptionChange(e)}
                  omitErrors>
                  {fastBundleOptions.map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))}
                </Select>
              </React.Fragment>
            );
          })}
        </>
      );
    }

    case ProductType.MULTIPLE_VARIANT: {
      return (
        <>
          <Select
            value={variantId}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => handleVariantChange(e)}
            omitErrors>
            {variants.map((variant, ix) => {
              const inStock = variant.availableForSale;
              return (
                <option key={ix} value={variant.id} disabled={!inStock}>
                  {variant.title}
                  {!inStock && " - OUT OF STOCK"}
                </option>
              );
            })}
          </Select>
        </>
      );
    }

    default: {
      return null;
    }
  }
};

export const LineItemSelect = (props: LineItemSelectProps) => {
  const variants = getVariantNodes(props.product);
  const hasMultipleVariants = variants.length > 1;
  const isBundle = props.product.tags.includes("bundle") && hasMultipleVariants;
  let productType;

  if (hasMultipleVariants) productType = ProductType.MULTIPLE_VARIANT;
  if (isBundle) productType = ProductType.BUNDLE;

  return (
    <Styled.LitemVariantSelect>
      <Styled.LitemVariantSelectWrapper>
        {CartSelector(productType, { ...props, variants })}
      </Styled.LitemVariantSelectWrapper>
    </Styled.LitemVariantSelect>
  );
};

export default LineItemSelect;
