import styled from "styled-components";
import { Button } from "@components/primitives/input";

export const Error = styled.div`
  margin: 1em;
`;

export const Wrapper = styled.div`
  text-align: center;
`;

export const ErrorButton = styled(Button)`
  line-height: 1.5;
`;
