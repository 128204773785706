import { Color, Device, Ease, Font, Z } from "@styles/constants";
import styled, { css } from "styled-components";

import {
  Heading4,
  XSmallBodyBold,
  BodyRegular,
  BodyMedium,
  XSmallBodyRegular,
  SmallBodyRegular,
  SmallBodyMedium,
} from "@styles/typography";

export const Button = styled.button`
  cursor: pointer;
`;

export const CartEmptyWrapper = styled.div`
  padding: 0 20px 20px;
`;

export const CartMessage = styled.div`
  background-color: ${Color.black20};
  padding: 20px;
`;

export const CartSavingsBanner = styled.div`
  background-color: ${Color.black100};
  font-size: 16px;
  color: ${Color.white};
  padding: 10px;
  display: flex;
  line-height: 18px;
  justify-content: center;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  font-size: 14px;
  color: ${Color.black60};
  @media ${Device.small} {
    line-height: 20px;
  }
`;

export const Header = styled.div`
  font-style: normal;
  font-weight: bold;
  padding: 20px 20px;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  box-shadow: 0 -1px 15px 0 rgba(0, 0, 0, 0.1);
  &.empty-cart {
    box-shadow: none;
  }
`;

export const ItemDescription = styled.div`
  font-size: 14px;
`;

export const LineItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Paragraph = styled.div`
  color: ${(props) => (props.color ? props.color : "#7e7e7e")};
  padding-top: 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
`;

export const Quantity = styled.div<{ immutable: boolean }>`
  padding-right: ${(props) => (props.immutable ? null : "10px")};
`;

export const QuantityWrapper = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 14px;
  color: #7e7e7e;
  margin-bottom: 0.25rem;
  min-width: 91px;
  justify-content: flex-start;
`;

export const Select = styled.select`
  border: 1px solid rgba(126, 126, 126, 0.3);
  maximum-scale: 1;
  &:focus {
    outline: none;
    box-shadow: none;
    transition: none;
    border: 1px solid rgba(126, 126, 126, 0.3);
  }
`;

export const SelectContainer = styled.div`
  display: inline-block;
  position: relative;
  color: black;
  min-width: 45px;
`;

export const Text = styled.p<{ fontFamily: string; fontSize: string; padding: string }>`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : Font.semibold)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "inherit")};
  color: ${(props) => (props.color ? props.color : "black")};
  padding: ${(props) => (props.padding ? props.padding : "inherit")};
`;

export const TextLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
  padding: 0 10px;
`;

export const Title = styled.div`
  color: ${(props) => (props.color ? props.color : "black")};
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const CartScrim = styled.div`
  z-index: ${Z.cartOverlay};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-color: rgba(100, 100, 100, 0.5);
  backdrop-filter: blur(10px);
`;

export const CartModal = styled.div<{ visible?: boolean }>`
  background-color: ${Color.white};
  z-index: ${Z.cartOverlay};
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: transform 0.25s ease, opacity 0.25s ease, visibility 0.25s ease;
  transform: translate3d(100%, 0, 0);

  @media ${Device.mediumUp} {
    max-width: 550px;
    top: 0;
  }

  ${(props: { visible?: boolean }) =>
    props.visible &&
    css`
      visibility: visible;
      opacity: 1;
      transform: translate3d(0, 0, 0);
    `}
`;

export const CartModalInner = styled.div`
  height: 100%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-wrap: nowrap;
  flex-direction: column;
  position: relative;
`;

export const CartModalContent = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

export const CloseBtn = styled.button`
  cursor: pointer;
  width: 60px;
  height: 58px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;

  svg {
    display: block;
    width: 24px;
    height: 24px;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;

    circle {
      opacity: 1;
    }
  }

  @media ${Device.mediumUp} {
    width: 35px;
    height: 35px;
    right: 40px;
    top: 16px;

    svg {
      width: 100%;
      height: auto;
    }
  }
`;

export const Heading = styled(Heading4)`
  flex: 1 1 auto;
  margin: 0;
  text-align: left;
  line-height: 1;
`;

// export const Header = styled.div`
//   background-color: ${Color.white};
//   padding: 0 0 0 21px;
//   min-height: 60px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   flex-wrap: nowrap;
//   box-shadow: 0 1px 0 ${Color.goldAsh};
//   position: relative;

//   @media ${Device.mediumUp} {
//     padding: 40px 43px 20px 43px;
//   }
// `;

export const Note = styled.div`
  background-color: ${Color.grayFill};
  padding: 21px 21px 30px 21px;

  @media ${Device.mediumUp} {
    border-top: 30px solid ${Color.white};
    border-right: 40px solid ${Color.white};
    border-left: 40px solid ${Color.white};
  }
`;

export const NoteHeading = styled(XSmallBodyBold)`
  display: block;
  margin: 0;
  padding: 0 0 5px;
`;

export const NoteCopy = styled(XSmallBodyRegular)`
  margin: 0;
  color: ${Color.grayBrand};

  &:not(:last-child) {
    padding: 0 0 15px;
  }
`;

export const Litems = styled.div`
  padding: 26px 21px;

  @media ${Device.mediumUp} {
    padding: 26px 40px;
  }
`;

export const Total = styled.div`
  padding: 4px 21px 13px 21px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media ${Device.mediumUp} {
    padding: 4px 40px 13px 40px;
  }
`;

export const TotalLabel = styled(BodyRegular)`
  margin: 0;
  flex: 0 1 auto;
`;

export const TotalPrice = styled(BodyMedium)`
  margin: 0;
  flex: 0 0 auto;

  .red {
    color: ${Color.blackBrand};
  }
`;

export const Footer = styled.div`
  box-shadow: 0 -1px 0 ${Color.goldAsh};
  background-color: ${Color.white};
  padding: 20px 21px;

  @media ${Device.mediumUp} {
    padding: 20px 40px;
  }

  p {
    margin: 0;
    padding: 15px 0 0;

    @media ${Device.mediumUp} {
      padding: 30px 0 0;
    }

    > a {
      color: ${Color.grayBrand};
    }
  }
`;

export const CheckoutButton = styled(BodyMedium)`
  line-height: 1;
  color: ${Color.white};
  background-color: ${Color.blackBrand};
  border: none;
  cursor: pointer;
  border-radius: 100px;
  min-height: 52px;
  padding: 5px;
  text-decoration: none;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color ${Ease.duration} ${Ease.out};

  &:focus,
  &:hover {
    color: ${Color.white};
    background-color: #4d4d4f;
  }
`;

export const Wrapper = styled.div`
  border-top: 1px solid ${Color.goldAsh};
  padding: 15px 21px 21px;

  @media ${Device.mediumUp} {
    padding: 15px 40px 21px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid ${Color.grayBrand};
  padding: 15px 0 9px;
`;

export const RowLabel = styled(SmallBodyRegular)`
  margin: 0;
  flex: 0 1 auto;
`;

export const RowPrice = styled(SmallBodyMedium)`
  margin: 0;
  flex: 0 0 auto;

  .red {
    color: ${Color.blackBrand};
  }
`;
