import { Input } from "@components/primitives/input";
import { CheckoutResource, useShopifyCart } from "@providers/cart";
import { useUserProfile } from "@providers/profile";
import { CheckoutErrorCode, convertErrorMessaging } from "@utils/convertErrorMessaging";
import { Form, Formik } from "formik";
import { PromoFormSubmission } from "./PromoForm.model";
import * as Styled from "./PromoForm.styled";

const onSubmit = async ({ code, checkout, addCode, addReferralToAuth, dispatch }: PromoFormSubmission) => {
  const { userErrors, discountApplications }: CheckoutResource = await addCode({
    checkoutId: checkout.id,
    code,
  });

  if (userErrors?.length) {
    throw userErrors;
  }
  /**
   * If no error occurred but the code was not applied,
   * throw a NOT_APPLICABLE error.
   */
  if (!userErrors?.length && !discountApplications?.length) {
    throw [{ message: CheckoutErrorCode.NOT_APPLICABLE }];
  }

  // if no errors, update the authState with the referralcode
  addReferralToAuth(code, dispatch)
};

const PromoForm = () => {
  const { checkout, addCode } = useShopifyCart();
  const { addReferralToAuth, dispatch } = useUserProfile();

  const initialValues = {
    code: "",
  };

  return (
    <Styled.Promo>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
          onSubmit({ code: values.code, checkout, addCode, addReferralToAuth, dispatch }).then(
            () => resetForm(),
            (checkoutUserErrors: Array<{ message: string }>) => {
              setErrors({ code: convertErrorMessaging(checkoutUserErrors[0]) });
              setSubmitting(false);
            }
          );
        }}>
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
          <Form>
            <Input
              label="Enter Promo Code"
              labelFilled="Promo Code"
              type="text"
              name="code"
              required
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.code?.toString() && touched.code?.toString() && errors.code?.toString()}
              value={values.code?.toString()}
            />
            <Styled.Button type="submit" disabled={isSubmitting}>
              Apply
            </Styled.Button>
          </Form>
        )}
      </Formik>
    </Styled.Promo>
  );
};

export default PromoForm;
