export namespace FastBundle {
  export interface Product {
    id: number;
    name: string;
    title: string;
    type: string;
    use_compare_at_price: boolean;
    description: string;
    enabled: boolean;
    uuid: string;
    priority: string | null;
    has_quantity_cap: boolean;
    allow_funnel: boolean;
    show_in_offers_page: boolean;
    show_uuid: string;
    items: Item[];
    discounts: Discount[];
    items_checked: boolean;
    items_selection_type: string;
    single_bap_variants: Variants;
  }

  export interface Item {
    id: number;
    item_id: number;
    show_in_page: boolean;
    plus_one_quantity: boolean;
    ignores_discount: boolean;
    quantity: number;
    is_required: boolean;
    image?: string;
    body_html: string;
    title: string;
    raw_price: number;
    price: string;
    handle: string;
    variants: Variant[];
    options: Option[];
  }

  export interface Variant {
    id: number;
    title: string;
    price: string;
    raw_price: number;
    compare_at_price: string;
    raw_compare_at_price: number;
    image: string;
    quantity: number;
    option1: string;
    option2: string | null;
    option3: string | null;
    inventory_management: string | null;
    inventory_policy: string;
    grams: number;
  }

  interface Option {
    id: number;
    name: string;
    position: number;
    values: string[];
  }

  interface Discount {
    id: number;
    type: string;
    value: number;
    quantity: number;
    max_quantity: number;
    description: string;
    translations: null;
  }

  export interface Variants {
    [x: string]: number;
  }
}

export const gidFastBundleProductVariantId = (
  variantId: `gid://shopify/ProductVariant/${string}`
) => variantId;
