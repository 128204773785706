import Button from "@components/ui/button";
import { useConsentManager } from "@providers/consent/ConsentManagerProvider";
import { ProfileAction, useUserProfile } from "@providers/profile";
import { AccountStatusType } from "@providers/profile/ProfileProvider.model";
import { accountSlug, redirectableRoutes } from "@utils/routes";
import { FormikHelpers } from "formik";
import { useRouter } from "next/router";
import { ParsedUrlQuery } from "querystring";
import { useEffect, useState } from "react";
import { SignInProps, SignInValues } from "./SignIn.model";
import SignInForm from "./SignInForm";

const verifyMessage = (email: string) => (
  <span>
    We’ve sent a verification email to <strong>{email}</strong> that includes a link to sign into
    your account.
  </span>
);
const pendingValidationMessage =
  "We’ve sent a verification email to you that includes a link to sign into your account.";
const needsPasswordMessage =
  "There is already a lululemon Studio account associated with that email address, but a password has not yet been set. Please choose a password to log in.";

const getRedirectPath = (query: ParsedUrlQuery) => {
  if (query.from && redirectableRoutes.includes(query.from.toString())) {
    return query.from.toString();
  }
  return accountSlug;
};

const SignIn = ({ loginState, setLoginState, handleLoginClose }: SignInProps) => {
  const router = useRouter();
  const [verificationSent, setVerificationSent] = useState<boolean>(false);
  const { saveConsent } = useConsentManager();
  const {
    setIsLoginOpen,
    checkEmail,
    login,
    dispatch,
    setPassword,
    profileState: { authState, profile },
    verification,
  } = useUserProfile();

  useEffect(() => {
    if (loginState.status == AccountStatusType.NEEDS_PASSWORD) {
      setLoginState((prevState) => ({
        ...prevState,
        title: "Please Set a Password",
        button_text: "Set Password",
        message: needsPasswordMessage,
      }));
      return;
    }

    if (loginState.status == AccountStatusType.SENT_VALIDATION) {
      setLoginState((prevState) => ({
        ...prevState,
        title: "Check Your Email",
        message: verifyMessage(loginState.active_email),
      }));
      return;
    }

    if (loginState.status == AccountStatusType.PENDING_VALIDATION) {
      setLoginState((prevState) => ({
        ...prevState,
        title: "Check Your Email",
        message: pendingValidationMessage,
      }));
      return;
    }

    if (loginState.status == AccountStatusType.VALID) {
      setLoginState((prevState) => ({
        ...prevState,
        button_text: "Sign In",
      }));
      return;
    }
  }, [loginState.status]);

  const handleSignInSubmit = async (
    values: SignInValues,
    { setErrors }: FormikHelpers<SignInValues>
  ) => {
    if (loginState.status == AccountStatusType.NEEDS_VERIFICATION) {
      try {
        const response = await checkEmail(values.email);
        const { is_verified, has_password } = response;

        setLoginState((prevState) => ({
          ...prevState,
          active_email: values.email,
        }));

        // user needs to create a password
        if (is_verified && !has_password) {
          setLoginState((prevState) => ({
            ...prevState,
            status: AccountStatusType.NEEDS_PASSWORD,
          }));
          return;
        }

        // Verified user can login
        if (is_verified && has_password) {
          setLoginState((prevState) => ({
            ...prevState,
            status: AccountStatusType.VALID,
          }));
          return;
        }

        // User has created a password but has yet to verify account via email
        // note: this will be possible until the mobile apps update their verification flow
        if (!is_verified) {
          setLoginState((prevState) => ({
            ...prevState,
            status: AccountStatusType.PENDING_VALIDATION,
          }));

          setVerificationSent(true);
          return;
        }
      } catch (e) {
        setErrors({ email: "This email is not recognized. Try again?" });
        return;
      }
    }

    if (loginState.status == AccountStatusType.NEEDS_PASSWORD) {
      setLoginState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      try {
        const { email, code } = verification;
        await setPassword(
          code,
          values.password,
          values.passwordConfirmation,
          dispatch,
          saveConsent
        );

        dispatch({
          type: ProfileAction.UPDATE_AUTH_USER,
          profile: profile,
          authState: {
            ...authState,
            value: {
              ...authState.value,
              flags: {
                wasLoginClicked: true,
                isLoginTracked: false,
              },
            },
          },
        });

        setIsLoginOpen(false);
        router.push(getRedirectPath(router.query));
      } catch (error) {
        setLoginState({
          ...loginState,
          message: "Unable to set password. Could not verify your account.",
        });
      }
      return;
    }

    if (loginState.status == AccountStatusType.VALID) {
      setLoginState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      try {
        await login({
          email: values.email,
          password: values.password,
          dispatch,
          saveConsent,
        });

        dispatch({
          type: ProfileAction.UPDATE_AUTH_USER,
          profile: profile,
          authState: {
            ...authState,
            value: {
              ...authState.value,
              flags: {
                wasLoginClicked: true,
                isLoginTracked: false,
              },
            },
          },
        });

        setIsLoginOpen(false);
        router.push(getRedirectPath(router.query));
      } catch (e) {
        setLoginState((prevState) => ({
          ...prevState,
          message: "Wrong email or password",
          loading: false,
        }));
      }
      return;
    }
  };

  const Verification = () => (
    <div className="field">
      <Button type="submit" onClick={handleLoginClose}>
        Okay
      </Button>
    </div>
  );

  return (
    <div className="sign-in">
      <h5>{loginState.title}</h5>
      {loginState.message && <p className="small-text">{loginState.message}</p>}
      {verificationSent ? (
        <Verification />
      ) : (
        <>
          <SignInForm
            loginState={loginState}
            setLoginState={setLoginState}
            handleSubmit={handleSignInSubmit}
          />
          {loginState.status == AccountStatusType.VALID && (
            <div className="field password-link-wrap">
              <button
                type="button"
                className="password-link"
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  setLoginState((prevState) => ({
                    ...prevState,
                    status: AccountStatusType.FORGOT_PASSWORD,
                  }));
                }}>
                Forgot password?
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SignIn;
