import { Color, Device, Ease } from "@styles/constants";
import { rem } from "@styles/helpers";
import {
  Eyebrow,
  Heading4CSS,
  XSmallBodyMediumCSS,
  XSmallBodyRegularCSS,
} from "@styles/typography";
import styled from "styled-components";

export const Footer = styled.footer`
  position: relative;
  padding: 40px 0;
  background: ${Color.blackBrand};
  color: ${Color.white};

  @media print {
    display: none;
  }

  @media ${Device.mediumUp} {
    padding: 80px 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    color: ${Color.white};
    text-decoration: none;
  }
`;

export const FooterAccordion = styled.div<{ ref?: any }>`
  .Collapsible {
    ${Heading4CSS}

    border-bottom: 0;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background: #3e3e3e;
    }
  }

  //The content within the collaspable area
  .Collapsible__contentInner {
    padding: 0 0 25px;
    font-size: 14px;

    p {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // The link which when clicked opens the collapsable area
  .Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    position: relative;
    padding: 20px 0;
    width: 100%;
    text-align: left;
    color: ${Color.white};

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }

    &:after {
      content: "";
      background: url("/images/plus_white.svg") center center no-repeat;
      width: 19px;
      height: 19px;
      position: absolute;
      right: 0;
      top: 20px;
      display: block;
      transition: transform 300ms;
    }

    &.is-open {
      &:after {
        transform: rotateZ(45deg);
      }

      + .Collapsible__contentOuter {
        visibility: visible;
        transition: height 200ms ease-out, visibility 0s linear 0s !important;
      }
    }

    &.is-disabled {
      opacity: 0.5;
      background-color: $gray;
    }
  }

  .Collapsible__contentOuter {
    visibility: hidden;
    transition: height 200ms ease-out, visibility 0s linear 200ms !important;
  }
`;

export const Row = styled.div`
  & + & {
    flex-flow: column nowrap;
    margin-top: 40px;
    align-items: flex-start;

    @media ${Device.mediumUp} {
      flex-flow: row nowrap;
      margin-top: 130px;
      align-items: center;
    }
`;

export const LogoWrap = styled.div``;

export const Logo = styled.div`
  a {
    position: absolute;
    width: 120px;
    min-height: 20px;
    display: block;
    padding: 0;
    opacity: 1;
    transition: opacity ${Ease.duration} ${Ease.out}, color ${Ease.duration} ${Ease.out};

    @media ${Device.mediumUp} {
      width: 180px;
      min-height: 30px;
    }

    &:hover {
      opacity: 0.75;
      transform: scaleX(-1);
      -webkit-transition: transform 1s ease-in-out;
    }
    -webkit-transition: transform 1s ease-in-out;
  }
`;

export const LinksWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 80px;

  @media ${Device.mediumUp} {
    margin-top: 0;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const Links = styled.div`
  flex: 0 1 auto;

  @media ${Device.mediumUp} {
    & + & {
      padding-left: 50px;
    }
  }
`;

export const LinksHeading = styled(Eyebrow)`
  margin-bottom: 36px;
`;

export const LinksNav = styled.nav``;

export const LinksList = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`;

export const LinksListItem = styled.li`
  @media ${Device.mediumUp} {
    max-width: ${rem(150)};
  }

  & + & {
    margin-top: 15px;
  }

  button {
    color: ${Color.white};
    cursor: pointer;

    @media ${Device.mediumUp} {
      ${XSmallBodyMediumCSS}
    }
  }

  span {
    display: block;

    @media ${Device.mediumUp} {
      ${XSmallBodyMediumCSS}
    }
  }

  a {
    display: block;
    transition: opacity ${Ease.duration} ${Ease.out}, color ${Ease.duration} ${Ease.out};

    &:hover {
      opacity: 0.75;
      color: ${Color.white};
    }

    @media ${Device.mediumUp} {
      ${XSmallBodyMediumCSS}
    }
  }
`;

export const SocialIcons = styled.div`
  ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
`;

export const SocialIcon = styled.li`
  width: 44px;
  height: 44px;

  & + & {
    margin-left: 15px;
  }

  a {
    width: 44px;
    height: 44px;
    padding: 0;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: opacity ${Ease.duration} ${Ease.out}, color ${Ease.duration} ${Ease.out};

    &:hover {
      opacity: 0.75;
    }
  }

  svg {
    display: block;
  }
`;

export const Colophon = styled.div`
  margin-top: 40px;

  ul {
    @media ${Device.mediumUp} {
      display: flex;
    }
  }

  a,
  button {
    color: ${Color.gray};
  }

  @media ${Device.mediumUp} {
    margin-top: 0;
  }
`;

export const ColophonItem = styled.li`
  position: relative;
  color: ${Color.gray};
  ${XSmallBodyRegularCSS}

  a, button {
    transition: color ${Ease.duration} ${Ease.out};
    cursor: pointer;

    &:hover {
      color: ${Color.white};
    }
  }

  svg {
    display: inline-block;
    vertical-align: middle;
  }

  & + & {
    margin-top: 15px;
  }

  @media ${Device.mediumUp} {
    & + & {
      margin-top: 0;
      margin-left: 13px;
      padding-left: 13px;
    }

    &:not(:last-child)::after {
      position: absolute;
      top: 0;
      right: 0;
      content: "|";
      right: -14px;
    }
  }
`;
