// Based on https://github.com/HCESrl/responsive-image-sizes

interface Sizes {
  desktop: number[];
  tabletPortrait: number[];
  smartphone: number[];
  [k: string]: number[];
}

/**
 * Base sizes to create images on
 */
const baseSizes = {
  desktop: [
    2880,
    2560,
    1920,
    1600, // same as tablet portrait first
    1440,
    1366,
    1024,
  ],
  tabletPortrait: [
    1600, // same as second entry for desktop
    1024,
    768,
  ],
  smartphone: [1242, 828, 768, 640],
};

/**
 * Granular base sizes (more images, more precise, higher top image size)
 */
const granularBaseSizes = {
  desktop: [
    2880,
    2560, // 2K iMac
    2048, // iPad Landscape
    1920,
    1680,
    1440,
    1366,
    1280,
    1024,
  ],
  tabletPortrait: [
    2048, // iPad Pro
    1536,
    1024,
    768,
  ],
  smartphone: [1242, 828, 750, 720, 640],
};

export type DeviceType = "all" | "desktop" | "tabletPortrait" | "smartphone" | "mobile";

export type Mode = "granular" | "standard" | "custom";

interface ResponsiveSizes {
  /** @param sourceImageWidth the width of the original image, in pixels (to avoid upscaling) */
  sourceImageWidth?: number;
  /** @param widthOnPage the actual width of the image on the page, in vw (% of width) */
  widthOnPage?: number;
  /** @param deviceType whether to generate sizes for desktop, tabletPortrait, smartphone or all */
  deviceType?: DeviceType;
  /** @param topSize the highest resolution to generate (fullHD is default, but if you need to go above that provide pixels) */
  topSize?: number;
  /** @param mode granular,  standard, or custom the first has more precise resolutions and creates more images */
  mode?: Mode;
  /** @param customSizes an object with desktop, tabletPortrait, smartphone custom sizes */
  customSizes?: Sizes;
}

/**
 * Returns an array of image sizes to produce
 */
export const responsiveSizes = ({
  sourceImageWidth = 1920,
  widthOnPage = 100,
  deviceType = "all",
  topSize = 1920,
  mode = "standard",
  customSizes,
}: ResponsiveSizes) => {
  let sizes = collectSizes(deviceType, mode, customSizes);
  // check that we're not producing images bigger than the maximum desired width
  sizes = checkTopSize(sizes, topSize);
  // get list of sizes based on width on page
  sizes = buildSizeList(sizes, widthOnPage);
  // check that we're not producing images that are bigger than the source image, to avoid unnecessary upsampling
  sizes = checkMaxImageSize(sizes, sourceImageWidth);
  return sizes;
};

/**
 * adapts the standard size list to the current requirement in terms of space
 */
const buildSizeList = (sizes: number[], widthOnPage: number) =>
  sizes.map((size) => Math.round((size / 100) * widthOnPage));

/**
 * Make sure we do not build images that are wider than the top required size
 */
const checkTopSize = (sizes: number[], topSize: number) => sizes.filter((size) => size <= topSize);

/**
 * Make sure we do not build images that are wider than the original, once we get the final image sizes
 */
const checkMaxImageSize = (sizes: number[], sourceImageWidth: number) =>
  sizes.filter((size) => size <= sourceImageWidth);

/**
 * Return all sizes for the desired mode
 */
const collectSizes = (deviceType: DeviceType, mode: Mode, customSizes: Sizes) => {
  let sizes: Sizes;
  switch (mode) {
    case "granular":
      sizes = granularBaseSizes;
      break;
    case "custom":
      sizes = customSizes;
      break;
    case "standard":
    default:
      sizes = baseSizes;
      break;
  }
  let res = [];
  switch (deviceType) {
    case "all": // images from all types, without duplicates
      res = Array.from(
        new Set(Object.keys(sizes).reduce((base, key) => base.concat(sizes[key]), []))
      ).sort((a, b) => {
        return a > b ? -1 : 1;
      });
      break;
    case "mobile": // tablet portrait and smartphone
      res = Array.from(new Set(sizes.smartphone.concat(sizes.tabletPortrait))).sort((a, b) => {
        return a > b ? -1 : 1;
      });
      break;
    default:
      res = sizes[deviceType];
      break;
  }
  return res;
};
