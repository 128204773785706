import { SanitySyncProductDocument, SanitySyncVariant } from "@models/product.model";
import { Shopify } from "@models/shopify.model";

export enum MoneyUnit {
  DOLLAR = "dollar",
  CENT = "cent",
}

/**
 * Take price number and formats it into currency. If price has zero cents,
 * the decimal will be stripped.
 *
 * @param value {number} Price to be formatted
 * @param unitType {MoneyUnit} Unit of money the price is coming in as. (Dollar or Cent)
 *                          Default: dollar.
 * @returns {string} Formatted price with currency sign.
 */
export const formatPrice = (value: number, unitType: MoneyUnit = MoneyUnit.DOLLAR): string => {
  const price = unitType === MoneyUnit.CENT ? value / 100 : value;

  let result: string = new Intl.NumberFormat("en-EN", {
    style: "currency",
    currency: "USD",
  }).format(price);
  // If the result has zero cents, strip the decimal
  result = Number(price) % 1 === 0 ? result.split(".").shift() : result;
  return result;
};

export const formatPriceRange = (price: Shopify.PriceRange) => {
  if (!price) {
    throw new Error("A price range must be provided.");
  }

  if (price.minVariantPrice === price.maxVariantPrice) {
    return formatPrice(price.minVariantPrice);
  }

  return `${formatPrice(price.minVariantPrice)} - ${formatPrice(price.maxVariantPrice)}`;
};

export const displayPrice = (
  price: number,
  trimDecimals: boolean = false,
  decimals: number = 2
) => {
  if (trimDecimals) {
    decimals = price % 1 ? 2 : 0;
  }
  return new Intl.NumberFormat("en-EN", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimals,
    maximumFractionDigits: 2,
  }).format(price);
};

/**
 * Will remove formatted display price. In the case that a range exists,
 * or if there is an invalid format, then we will return 0.
 * 
 * @param price Formatted string
 * @returns number
 */
export const stripPriceFormat = (price: string): number => {
  if (!price) return 0;
  if (price.includes('-')) return 0;
  const nonFormated = price.replaceAll(/[^0-9.]/ig,"");
  return Number(nonFormated) || 0;
}

export const productDisplayPrice = (price: Shopify.PriceRange) => {
  if (!price) return "";

  if (price?.minVariantPrice === price?.maxVariantPrice)
    return `${formatPrice(price?.minVariantPrice)}`;

  return `${formatPrice(price.minVariantPrice)} - ${formatPrice(price.maxVariantPrice)}`;
};

export const getDiscountedPrice = (lineItem: Shopify.LineItem) => {
  return lineItem.discountAllocations.reduce((lineItemPrice, allocation) => {
    return lineItemPrice - Number(allocation.allocatedAmount.amount);
  }, lineItem.quantity * Number(lineItem.variant.price.amount));
};
/**
 * @desc Get the minimum price and compareAt price of a product.
 *     If a variant is provided, getPriceComparison will return the variant's
 *     price and compareAt price.
 * @param {Shopify.Product | SanitySyncProductDocument} product
 * @param {Shopify.ProductVariant | SanitySyncVariant} variant
 * @returns {PriceComparison}
 */

export interface PriceComparison {
  actual: number;
  strikethrough: number;
  showComparison: boolean;
}
/**
 * @desc Given a product document, storefront product, synced variant, or storefront variant,
 *     returns a PriceComparison object containing the actual price of the context object, the
 *     strikethough price of the context object, and a boolean showComparison property indicating
 *     whether or not the strikethrough pricing should be displayed.
 * @param {Shopify.Product | SanitySyncProductDocument} product
 * @param {Shopify.ProductVariant | SanitySyncVariant} variant
 * @returns {PriceComparison}
 */
export const formatPriceComparison = (
  product: Shopify.Product | SanitySyncProductDocument,
  variant?: Shopify.ProductVariant | SanitySyncVariant
): PriceComparison => {
  /**
   * @desc Validates the difference between the actual and strikethrough prices of the
   *     context object. When the return value is true, the strikethrough pricing
   *     should be displayed.
   * @param {number} actual
   * @param {number} strikethrough
   * @returns {boolean}
   */
  const validateDifference = (actual: number, strikethrough: number) => {
    return actual !== strikethrough && strikethrough !== 0 && !isNaN(strikethrough);
  };
  /**
   * variant is a SanitySyncVariant
   */
  if (typeof variant?.price === "string") {
    const actual = Number(variant.price);
    const strikethrough = Number(variant.compareAtPrice);
    return { actual, strikethrough, showComparison: validateDifference(actual, strikethrough) };
  }
  /**
   * variant is a Shopify.ProductVariant
   */
  if (typeof variant?.price === "object" && typeof variant?.compareAtPrice === "object") {
    const actual = Number(variant.price.amount);
    const strikethrough = Number(variant.compareAtPrice?.amount);
    return { actual, strikethrough, showComparison: validateDifference(actual, strikethrough) };
  }
  /**
   * product is a sanitySyncProductDocument
   */
  if (typeof product.priceRange.minVariantPrice === "number") {
    const actual = product.priceRange.minVariantPrice;
    const strikethrough = Number(product.compareAtPriceRange.minVariantPrice.amount);
    return { actual, strikethrough, showComparison: validateDifference(actual, strikethrough) };
  }
  /**
   * product is a Shopify.Product
   */
  const actual = Number(product.priceRange.minVariantPrice.amount);
  const strikethrough = Number(product.compareAtPriceRange.minVariantPrice.amount);
  return { actual, strikethrough, showComparison: validateDifference(actual, strikethrough) };
};
