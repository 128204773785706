import { Form, Formik } from "formik";
import * as Yup from "yup";
import { invalidField } from "@utils/validations";
import Button from "@components/ui/button";
import { Input } from "@components/primitives/input";
import * as Styled from "./EmailPrivacyForm.styled";
import { useState } from "react";

const privacyEmailSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .min(1, invalidField("first name", 1))
    .max(50, invalidField("first name", 1))
    .required("First Name Required"),
  lastName: Yup.string()
    .trim()
    .min(1, invalidField("last name", 1))
    .max(50, invalidField("last name", 1))
    .required("Last Name Required"),
  email: Yup.string()
    .email("Please Enter a Valid Email")
    .required("Valid Email Required")
    .test("no-.con", ".con is not valid, did you mean .com?", (value) => {
      if (value) return value.match(/\.con\s*$/i) === null;
      else return false;
    }),
});

export interface PrivacyForm {
  firstName?: string;
  lastName?: string;
  email?: string;
}

interface Props {
  handleSubmit: (data: PrivacyForm) => void;
}

const EmailForm = ({ handleSubmit }) => {
  return (
    <>
      <div>
        <span>
          {"If you wish to opt out of any \"sales,\" \"sharing,\" or \"targeted advertising\" \
          associated with your email address, please complete and submit the form below."}
        </span>
      </div>
      <div>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
          }}
          validationSchema={privacyEmailSchema}
          onSubmit={handleSubmit}
          data-testid="email-opt-out-form">
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => {
            return (
              <Form>
                <Input
                  type="text"
                  name="firstName"
                  label="First Name"
                  autoComplete="first name"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  error={errors.firstName && touched.firstName && errors.firstName}
                  data-testid="first-name-input"
                />
                <Input
                  type="text"
                  name="lastName"
                  label="Last Name"
                  autoComplete="last name"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  error={errors.lastName && touched.lastName && errors.lastName}
                  data-testid="last-name-input"
                />
                <Input
                  label="Email Address"
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email && touched.email && errors.email}
                  value={values.email}
                  data-testid="email-input"
                />
                <Button
                  type="submit"
                  testId="btn-submit-consent"
                  invert
                  text="Submit Form"
                  disabled={isSubmitting}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

const SubmittedContent = () => (
  <div>
    <p>Thank you for submitting your request. A company representative will review your request.</p>
  </div>
);

const EmailPrivacyForm = ({ handleSubmit }: Props) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const component = isSubmitted ? (
    <SubmittedContent />
  ) : (
    <EmailForm
      handleSubmit={(formData) => {
        handleSubmit(formData);
        setIsSubmitted(true);
      }}
    />
  );
  return (
    <Styled.EmailPrivacy data-testid="email-privacy-form">
      <Styled.HeaderSection>
        <h4>Your Privacy Choices</h4>
      </Styled.HeaderSection>
      {component}
    </Styled.EmailPrivacy>
  );
};

export default EmailPrivacyForm;
