import * as Yup from "yup";
// TODO MIR-687 - Postal Code validation
// import api from "src/api/echeckouts";

import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from "@utils/constants";

const phoneRegExp =
  /^[2-9]{1}[0-9]{2}[\s.-]?[0-9]{3}[\s.-]?[0-9]{4}$|^[\s+.-]?1?[\s.-]?[2-9]{1}[0-9]{2}[\s.-]?[0-9]{3}[\s.-]?[0-9]{4}$/;
const zipRegExp = /(^\d{5}(?:[\s]?[-\s][\s]?\d{4})?$)/;
const zipRegExpCA =
  /^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]\d[abceghjklmnprstvxyABCEGHJKLMNPRSTVWXYZ][ -]?\d[abceghjklmnprstvxyABCEGHJKLMNPRSTVWXYZ]\d$/;
const amazonOrderNumberRegExp = /(^\d{3}-\d{7}-\d{7}$)/;

export const invalidField = (name: string, min = 6, max = 50) =>
  `Invalid ${name}. Must be between ${min} and ${max} characters`;

export const emailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please Enter a Valid Email")
    .required("Valid Email Required")
    .test("no-.con", ".con is not valid, did you mean .com?", (value) => {
      if (value) return value.match(/\.con\s*$/i) === null;
      else return false;
    }),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Valid Email Required"),
  password: Yup.string()
    .min(PASSWORD_MIN_LENGTH, invalidField("Password", undefined, PASSWORD_MAX_LENGTH))
    .max(PASSWORD_MAX_LENGTH, invalidField("Password", undefined, PASSWORD_MAX_LENGTH)),
});

export const verificationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Valid Email Required"),
  password: Yup.string()
    .min(PASSWORD_MIN_LENGTH, invalidField("Password", undefined, PASSWORD_MAX_LENGTH))
    .max(PASSWORD_MAX_LENGTH, invalidField("Password", undefined, PASSWORD_MAX_LENGTH))
    .required("Password Required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .min(PASSWORD_MIN_LENGTH, invalidField("passwordConfirmation", undefined, PASSWORD_MAX_LENGTH))
    .max(PASSWORD_MAX_LENGTH, invalidField("passwordConfirmation", undefined, PASSWORD_MAX_LENGTH))
    .required("Password Required"),
});

export const accountTransferFormSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Valid Email Required"),
  password: Yup.string()
    .min(PASSWORD_MIN_LENGTH, invalidField("Password", undefined, PASSWORD_MAX_LENGTH))
    .max(PASSWORD_MAX_LENGTH, invalidField("Password", undefined, PASSWORD_MAX_LENGTH)),
  transferToEmail: Yup.string().email("Invalid Email").required("Valid Email Required"),
  reason: Yup.string().required("Please select a reason for transfer"),
});

export const giftSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(1, invalidField("Name", 1))
    .max(50, invalidField("Name", 1))
    .matches(/\w+\s+\w+/, "Full Name Required")
    .required("Name Required"),
  phone: Yup.string()
    .trim()
    .matches(phoneRegExp, "Must be a Valid Phone Number")
    .required("Phone Number Required"),
  city: Yup.string()
    .trim()
    .min(2, invalidField("City", 2))
    .max(50, invalidField("City", 2))
    .required("City Required"),
  stateId: Yup.string().required("State Required"),
});

export const addressSchema = Yup.object().shape({
  line1: Yup.string()
    .trim()
    .min(2, invalidField("Address", 2))
    .max(50, invalidField("Address", 2))
    .required("Address Required"),
  city: Yup.string()
    .trim()
    .min(2, invalidField("City", 2))
    .max(50, invalidField("City", 2))
    .required("City Required"),
  postal_code: Yup.string()
    .matches(zipRegExp, "Must be a Valid Zipcode")
    .required("Zip Code Required")
    .test(
      "checkPostalCodeInServiceArea",
      "Please contact hello@lululemonstudio.com for more information about delivery options to your area.",
      async (value) => {
        if (value) {
          // TODO MIR-687 - Postal Code validation
          // const { body } = await api.validatePostalCode(value);
          // return body["serviceablePostalCode"] == true;
        } else {
          return false;
        }
      }
    ),
  state: Yup.string().required("State Required"),
});

export const billingAddressSchema = addressSchema.shape({
  postal_code: Yup.string()
    .matches(zipRegExp, "Must be a Valid Zipcode")
    .required("Zip Code Required"),
});

export const addressSchemaCA = Yup.object().shape({
  line1: Yup.string()
    .trim()
    .min(2, invalidField("Address", 2))
    .max(50, invalidField("Address", 2))
    .required("Address Required"),
  city: Yup.string()
    .trim()
    .min(2, invalidField("City", 2))
    .max(50, invalidField("City", 2))
    .required("City Required"),

  postal_code: Yup.string()
    .matches(zipRegExpCA, "Must be a Valid Postal Code")
    .required("Postal Code Required")
    .test(
      "checkPostalCodeInServiceArea",
      "We do not deliver to your postal code. Please contact hellocanada@mirror.co for more information.",
      async (value) => {
        if (value) {
          // TODO MIR-687 - Postal Code validation
          // const { body } = await api.validatePostalCode(value);
          // return body["serviceablePostalCode"] == true;
        } else {
          return false;
        }
      }
    ),
  state: Yup.string().required("Province Required"),
});

export const billingAddressSchemaCA = addressSchemaCA.shape({
  postal_code: Yup.string()
    .matches(zipRegExpCA, "Must be a Valid Postal Code")
    .required("Postal Code Required"),
});

export const creditCardSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(1, invalidField("Name", 1))
    .max(50, invalidField("Name", 1))
    .required("Name Required"),
});

export const creditCardAndAddressSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(1, invalidField("Name", 1))
    .max(50, invalidField("Name", 1))
    .required("Name Required"),
  line1: Yup.string()
    .trim()
    .min(2, invalidField("Address", 2))
    .max(50, invalidField("Address", 2))
    .required("Address Required"),
  city: Yup.string()
    .trim()
    .min(2, invalidField("City", 2))
    .max(50, invalidField("City", 2))
    .required("City Required"),
  postal_code: Yup.string()
    .matches(zipRegExp, "Must be a Valid Zipcode")
    .required("Zip Code Required"),
  state: Yup.string().required("State Required"),
});

export const creditCardAndAddressSchemaCA = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(1, invalidField("Name", 1))
    .max(50, invalidField("Name", 1))
    .required("Name Required"),
  line1: Yup.string()
    .trim()
    .min(2, invalidField("Address", 2))
    .max(50, invalidField("Address", 2))
    .required("Address Required"),
  city: Yup.string()
    .trim()
    .min(2, invalidField("City", 2))
    .max(50, invalidField("City", 2))
    .required("City Required"),
  postal_code: Yup.string()
    .matches(zipRegExpCA, "Must be a Valid Postal Code")
    .required("Postal Code Required"),
  state: Yup.string().required("State Required"),
});

export const reviewSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(1, invalidField("Name", 1))
    .max(50, invalidField("Name", 1))
    .required("Name Required"),
  email: Yup.string().trim().email("Invalid Email").required("Valid Email Required"),
  city: Yup.string()
    .trim()
    .min(2, invalidField("City", 2))
    .max(50, invalidField("City", 2))
    .required("City Required"),
  state: Yup.string().required("State Required"),
  title: Yup.string()
    .trim()
    .min(3, invalidField("Title", 1))
    .max(50, invalidField("Title", 1))
    .required("Title Required"),
  review: Yup.string()
    .trim()
    .min(3, invalidField("Review", 1))
    .max(2000, invalidField("Review", 1))
    .required("Review Required"),
  rating: Yup.string().required("Rating Required"),
});

export const passwordSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(PASSWORD_MIN_LENGTH, invalidField("Password", undefined, PASSWORD_MAX_LENGTH))
    .max(PASSWORD_MAX_LENGTH, invalidField("Password", undefined, PASSWORD_MAX_LENGTH))
    .required("Password Required"),
  password: Yup.string()
    .min(PASSWORD_MIN_LENGTH, invalidField("Password", undefined, PASSWORD_MAX_LENGTH))
    .max(PASSWORD_MAX_LENGTH, invalidField("Password", undefined, PASSWORD_MAX_LENGTH))
    .required("Password Required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .min(PASSWORD_MIN_LENGTH, invalidField("passwordConfirmation", undefined, PASSWORD_MAX_LENGTH))
    .max(PASSWORD_MAX_LENGTH, invalidField("passwordConfirmation", undefined, PASSWORD_MAX_LENGTH))
    .required("Password Required"),
});

export const orderLookupSchema = Yup.object().shape({
  amazonOrderNumber: Yup.string()
    .matches(amazonOrderNumberRegExp, "Must be a Valid Amazon Order Number")
    .required("Amazon Order Number Required"),
  zipCode: Yup.string().matches(zipRegExp, "Must be a Valid Zipcode").required("Zip Code Required"),
});

export const registerAmazonUserSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please Enter a Valid Email")
    .required("Valid Email Required")
    .test("no-.con", ".con is not valid, did you mean .com?", (value) => {
      if (value) return value.match(/\.con\s*$/i) === null;
      else return false;
    }),
  emailConfirm: Yup.string()
    .oneOf([Yup.ref("email")], "Emails must match")
    .required("Valid Email Required")
    .test("no-.con", ".con is not valid, did you mean .com?", (value) => {
      if (value) return value.match(/\.con\s*$/i) === null;
      else return false;
    }),
  password: Yup.string()
    .min(PASSWORD_MIN_LENGTH, invalidField("Password", undefined, PASSWORD_MAX_LENGTH))
    .max(PASSWORD_MAX_LENGTH, invalidField("Password", undefined, PASSWORD_MAX_LENGTH))
    .required("Password Required"),
});
