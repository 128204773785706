import * as request from "superagent";
import React, { useEffect, useState } from "react";
import { FooterConsentFields } from "@models/footer.model";
import { ReactComponent as CCPA_SVG } from "@images/ccpa-icon.svg";
import ModalContainer from "@components/modals/ModalContainer";
import * as Styled from "./ConsentManagerModal.styled";
import EmailPrivacyForm from "../EmailPrivacyForm/EmailPrivacyForm";
import SegmentConsentManager from "../SegmentConsentManager/SegmentConsentManager";
import { ProfileEndpoints } from "providers/profile/ProfileProvider.model";
import { HttpMethods, buildSignatureAuthHeaders } from "@services/requests";
import { useConsentManager } from "@providers/consent/ConsentManagerProvider";
import { apiBaseURL } from "@utils/apiHelpers";

import { useSearchParams } from 'next/navigation'

// DEVS: Once this open issue is resolve https://github.com/segmentio/consent-manager/issues/303
// please use the react component { ConsentManager, openConsentManager } from "@segment/consent-manager" instead of the standalone-script below

interface ConsentManagerModalType {
  consent_fields: FooterConsentFields;
}

export enum PRIVACY_SECTIONS {
  TOOLS = "tools",
  EMAIL = "email",
}

export const StudioConsentModal = ({ content }) => {
  const [currentSection, setCurrentSection] = useState<PRIVACY_SECTIONS>(PRIVACY_SECTIONS.TOOLS);
  let currentConsentComponent;

  const handleSubmit = async (props) => {
    try {
      const { authorization_header, http_signature_created } = buildSignatureAuthHeaders(
        HttpMethods.POST,
        `${apiBaseURL()}${ProfileEndpoints.OPT_OUT}`
      );
      const reqData = await request
        .post(`${apiBaseURL()}${ProfileEndpoints.OPT_OUT}`)
        .set("Authorization", authorization_header)
        .set("Created", http_signature_created)
        .send({
          first_name: props.firstName,
          last_name: props.lastName,
          email: props.email,
        });
      return reqData;
    } catch (err) {
      console.error(err);
    }
  };

  if (currentSection === PRIVACY_SECTIONS.TOOLS) {
    currentConsentComponent = <SegmentConsentManager content={content} />;
  } else {
    currentConsentComponent = <EmailPrivacyForm handleSubmit={handleSubmit} />;
  }

  return (
    <Styled.PrivacyLayout>
      <Styled.PrivacyMenu>
        <Styled.PrivacySectionList data-testid="privacy-menu">
          <Styled.PrivacyMenuItem
            onClick={(e) => {
              e.preventDefault();
              setCurrentSection(PRIVACY_SECTIONS.TOOLS);
            }}
            isActive={currentSection === PRIVACY_SECTIONS.TOOLS}
            data-testid="menu-item-preferences">
            <span>Privacy Preference Center</span>
          </Styled.PrivacyMenuItem>
          <Styled.PrivacyMenuItem
            onClick={(e) => {
              e.preventDefault();
              setCurrentSection(PRIVACY_SECTIONS.EMAIL);
            }}
            isActive={currentSection === PRIVACY_SECTIONS.EMAIL}
            data-testid="menu-item-email">
            <span>Your Privacy Choices</span>
          </Styled.PrivacyMenuItem>
        </Styled.PrivacySectionList>
      </Styled.PrivacyMenu>

      <Styled.PrivacyContent>{currentConsentComponent}</Styled.PrivacyContent>
    </Styled.PrivacyLayout>
  );
};

export const ConsentManagerModal = ({ consent_fields }: ConsentManagerModalType) => {
  const { closeModal, openModal, isModalOpen } = useConsentManager();
  const { consent_preferences_dialog_cta_text } = consent_fields;
  const searchParams = useSearchParams();

  useEffect(() => {
    if (searchParams.get('privacy-preferences')){
      openModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <button data-testid="privacy-preferences-cta" type="button" onClick={openModal}>
        {consent_preferences_dialog_cta_text} <CCPA_SVG />
      </button>
      <div>
        <ModalContainer
          data-testid="modal-container"
          toggleModal={closeModal}
          mounted={isModalOpen}>
          <StudioConsentModal content={consent_fields} />
        </ModalContainer>
      </div>
    </>
  );
};

export default ConsentManagerModal;
