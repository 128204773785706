import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { motion } from "framer-motion";
import { ModalProps } from "./Modal.model";
import { ReactComponent as CloseIcon } from "@images/icon_close-circular.svg";
import { Device, Color } from "@styles/constants";
import styled, { css } from "styled-components";
import { APPLICATION_NODE } from "@utils/constants";
import { useIsFirstRender } from '@utils/useIsFirstRender';
import { events } from "@providers/analytics/analytics";
import { useUserProfile } from "@providers/profile";

interface ModalContainerProps extends ModalProps {
  toggleModal: () => void;
  children: React.ReactNode;
  maxWidth?: number;
  name?: string;
}

const ModalContainer = ({ toggleModal, mounted = false, maxWidth = 780, name, children }: ModalContainerProps) => {
  const isFirstRender = useIsFirstRender();
  const { profileState: { authState } } = useUserProfile();

  useEffect(() => {
    if (typeof document !== "undefined") {
      ReactModal.setAppElement(document.getElementById(APPLICATION_NODE));
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender) {
      const segmentEvent = mounted ? events.modal.opened : events.modal.closed;
      segmentEvent(authState.value, name);
    }
  // Don't want to run when isFirstRender changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted]);


  return (
    <ReactModal
      isOpen={mounted}
      onRequestClose={toggleModal}
      contentLabel={"Open Modal"}
      overlayClassName={"llls__modal__overlay"}
      className={"llls__modal__content"}>
      <ModalScrollWrap
        initial={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        animate={{ opacity: 1 }}>
        <ModalContent initial={{ y: "-30px" }} transition={{ duration: 0.2 }} animate={{ y: 0 }}>
          <ModalContentInner maxWidth={maxWidth}>
            {children}

            <ModalCloseBtn aria-label="Close Modal" onClick={toggleModal}>
              <CloseIcon />
            </ModalCloseBtn>
          </ModalContentInner>
        </ModalContent>
      </ModalScrollWrap>
    </ReactModal>
  );
};

export default ModalContainer;

const ModalScrollWrap = styled(motion.div)`
  display: flex;
  height: 100%;

  @media ${Device.mediumUp} {
    align-items: center;
  }
`;

const ModalContent = styled(motion.div)`
  flex: 1 1 100%;
  height: 100%;
  min-height: 0;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden auto;
  -webkit-overflow-scrolling: touch;
  background-color: ${Color.white};

  @media ${Device.mediumUp} {
    background-color: transparent;
    padding: 10px;
    height: auto;
  }
`;

const ModalContentInner = styled.div<{ maxWidth: number }>`
  background-color: ${Color.white};
  flex: 0 0 auto;
  position: relative;
  width: 100%;

  @media ${Device.mediumUp} {
    border-radius: 20px;
    width: 100%;
  }

  ${(props) =>
    props.maxWidth &&
    css`
      @media ${Device.mediumUp} {
        max-width: ${props.maxWidth}px;
      }
    `}
`;

const ModalCloseBtn = styled.button`
  cursor: pointer;
  width: 36px;
  height: 36px;
  position: fixed;
  inset: 21px 21px auto auto;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }

  @media ${Device.mediumUp} {
    position: absolute;
    inset: 30px 30px auto auto;
  }
`;
