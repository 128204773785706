import { Shopify } from "@models/shopify.model";
import * as Styled from "../CartOverlay.styled";
import Coupon from "../coupon";

export interface CouponListProps {
  discountApplications: Shopify.DiscountApplication[];
}

const CouponList = ({ discountApplications }: CouponListProps) => {
  if (!discountApplications.length) return null;

  return (
    <Styled.Row>
      <Styled.RowLabel>Promo Code</Styled.RowLabel>
      <div className="coupon-section">
        <div className="coupons">
          {discountApplications.map((application, i) => (
            <Coupon key={i} application={application} showRemove />
          ))}
        </div>
      </div>
    </Styled.Row>
  );
};

export default CouponList;
