import { AccountStatusType, ErrorResponse } from "@providers/profile/ProfileProvider.model";

export interface LoginStateProps {
  active_email: string;
  title: string;
  button_text: string;
  message: string | React.ReactElement;
  status: string;
  loading: boolean;
}

export const defaultLoginState = {
  active_email: "",
  title: "Please sign in to continue",
  button_text: "Next",
  message: "",
  status: AccountStatusType.NEEDS_VERIFICATION,
  loading: false,
};

export interface LoginProps {
  isLoginOpen: boolean;
  handleLoginClose: () => void;
}

export enum VerificationStatus {
  INACTIVE = "inactive",
  ACTIVE = "active",
  ERROR = "error",
  SUCCESS = "success",
}

export interface UserVerificationProps {
  status: VerificationStatus;
  error: ErrorResponse | null;
}

export const defaultUserVerification = {
  status: VerificationStatus.INACTIVE,
  error: null,
};
