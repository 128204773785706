import * as Styled from "./PromoButton.styled";
import PromoForm from "../promo-form";
import React, { useState } from "react";

const handleClick = (
  visible: boolean,
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setVisible(!visible);
};

const PromoButton = () => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <>
      <Styled.PromoButton as="button" onClick={() => handleClick(visible, setVisible)}>
        Enter Promo Code
      </Styled.PromoButton>
      {visible && (
        <Styled.PromoFormWrap>
          <PromoForm />
        </Styled.PromoFormWrap>
      )}
    </>
  );
};

export default PromoButton;
