import { useState } from "react";
import { Form, Formik } from "formik";
import { Button, Input } from "@components/primitives/input";
import { useUserProfile } from "@providers/profile";
import { events, identify } from "@providers/analytics/analytics";


export interface ForgotPasswordProps {
  email?: string;
  handleLoginClose: () => void;
}

const ForgotPassword = ({ email, handleLoginClose }: ForgotPasswordProps) => {
  const { 
    forgotPassword,
    profileState: { authState }
  } = useUserProfile();
  const [requestValid, setRequestValid] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (email: string) => {
    try {
      await forgotPassword(email);
      setRequestValid(true);
    } catch (err) {
      setError(err);
    }
    finally {
      identify(authState.value, { email })
      events.password.reset(authState.value, email)
    }
  };

  return requestValid ? (
    <div className="forgot-password sent">
      <p>We’ll send you an email with a link to reset your password.</p>
      <div className="field">
        <Button type="submit" onClick={handleLoginClose}>
          Okay
        </Button>
      </div>
    </div>
  ) : (
    <div className="forgot-password">
      <div className="prompt">Enter your email address to reset your password.</div>
      {error && <p className="error">{error}</p>}
      <Formik
        initialValues={{ email: email || "" }}
        enableReinitialize={true}
        onSubmit={({ email }, { setSubmitting }) => {
          handleSubmit(email).then(() => setSubmitting(false));
        }}>
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => {
          return (
            <Form>
              <Input
                label="Email"
                type="email"
                name="email"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email && touched.email && errors.email}
                value={values.email}
              />
              <div className="field">
                <Button type="submit" disabled={isSubmitting}>
                  Reset Password
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
