// Pages
export const classesSlug = "/classes";
export const instructorsSlug = "/instructors";
export const partnersSlug = "/partners";
export const shopSlug = "/shop";
export const comparePlansSlug = "/compare-plans/";
export const packagesSlug = "/shop/packages";
export const accessoriesSlug = "/accessories";
export const hardwareSlug = "/shop/mirror";

export const accountSlug = "/account";
export const billingSlug = "/billing";
export const ordersSlug = "/account/orders";
export const paymentsSlug = "/account/payments";

// Dynamic Routes
export const classesRoute = "/classes/[slug]";
export const instructorRoute = "/instructors/[slug]";
export const partnerRoute = "/partners/[slug]";
export const shopRoute = "/shop/[slug]";

export const redirectableRoutes = [billingSlug, ordersSlug, paymentsSlug];
