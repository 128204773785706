import parse from "html-react-parser";
import styled, { css } from "styled-components";
import { BodyRegular, BodyRegularCSS, Heading3, SmallBodyRegular } from "@styles/typography";
import { Color, Device, Font } from "@styles/constants";
import ModalContainer from "@components/modals/ModalContainer";
import PortableText from "@components/portable-text";

import { ModalProps } from "./Modal.model";
import { Sanity } from "@models/sanity.model";

interface OwnProps extends ModalProps {
  toggleModal: () => void;
  cmsNode: {
    title?: string;
    text?: string;
    smallText?: React.ReactNode;
    portableText?: Sanity.PortableText;
  };
}

const TextModal = ({ toggleModal, mounted = false, cmsNode }: OwnProps) => (
  <ModalContainer toggleModal={toggleModal} mounted={mounted} name={cmsNode?.title}>
    <Content>
      {cmsNode?.title && <Heading as="h2">{cmsNode?.title}</Heading>}
      {cmsNode?.text && <Copy>{parse(cmsNode?.text)}</Copy>}
      {cmsNode?.portableText && (
        <PortableTextWrapper>
          <PortableText value={cmsNode.portableText} />
        </PortableTextWrapper>
      )}
      {cmsNode?.smallText && <Label as="span">{cmsNode?.smallText}</Label>}
    </Content>
  </ModalContainer>
);

export default TextModal;

const Content = styled.div`
  padding: 80px 21px 80px;
  max-width: 660px;
  margin: 0 auto;

  @media ${Device.mediumUp} {
    padding: 60px 40px 40px;
  }
`;

const Heading = styled(Heading3)`
  text-align: left;
  margin: 0;
  padding: 0 0 20px;
  max-width: 100%;
`;

const BodyCopyCSS = css`
  margin: 0;
  padding: 0 0 30px 0;
`;

const Copy = styled(BodyRegular)`
  ${BodyCopyCSS}
`;

const PortableTextWrapper = styled.div`
  p {
    ${BodyRegularCSS}
    ${BodyCopyCSS}
  }
`;

const Label = styled(SmallBodyRegular)`
  color: ${Color.grayBrand};
  border-top: 1px solid ${Color.black};
  padding: 20px 0 0 0;
  margin: 0;
  display: block;

  a {
    font-family: ${Font.medium};
    text-decoration: none;
  }
`;
