import { Color } from "@styles/constants";
import styled from "styled-components";

export const PrivacyLayout = styled.div`
  display: flex;
  gap: 50px;
  margin: 30px 20px;
`;

export const PrivacySectionList = styled.ul`
  list-style: none;
  margin: 0px;
  li {
    active: ${Color.blackBrand}
  }
`;


export const PrivacyMenu = styled.section`
  width: 20%;
`;

export const PrivacyMenuItem = styled.li<{isActive?: boolean}>`
  background-color: ${({ isActive }) => isActive ? Color.blackBrand : Color.white};
  color: ${({ isActive }) => isActive ? Color.white : Color.blackBrand };
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
`

export const PrivacyContent = styled.section`
  width: 80%;
  height: 500px;
`;
