import styled from "styled-components";
import { Color } from "@styles/constants";

export const StyledPrivacyBanner = styled.div`
  position: sticky;
  bottom: 0px;
  color: #fff;
  background-color: #1a1a1a;
  padding: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  z-index: 4;
  flex-direction: column;
  align-items: center;
  border: 2px solid ${Color.grayBrand};
`;

export const TextBanner = styled.p`
  width: 65%;
  a {
    color: #fff;
    &:hover {
      color: ${Color.grayBrand};
    }
  }
`;

export const SVGImage = styled.div`
  height: 25px;
  width: 25px;
  position: absolute;
  right: 2%;
`;
