import styled from "styled-components";

export const CTAContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const ToolsList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  li {
    list-style-type: none;
  }
`;
export const HeaderSection = styled.section`
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(67, 90, 111, 0.08);
`;

export const ConsentContent = styled.section`
  height: 450px;
  overflow-y: auto;
  padding-bottom: 50px;
`;
