import styled from "styled-components";

export const EmailPrivacy = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const HeaderSection = styled.section`
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(67, 90, 111, 0.08);
`
