import type { CategoryPreferences } from "@segment/consent-manager/types/types";

import { FooterConsentFields } from "@models/footer.model";
import { PortableText } from "@portabletext/react";
import { useConsentManager } from "@providers/consent/ConsentManagerProvider";

import * as Styled from "./SegmentConsentManager.styled";
import ConsentForm from "../ConsentForm/ConsentForm";

interface SegmentConsentManagerProps {
  content: FooterConsentFields;
}
const SegmentConsentManager = ({ content }: SegmentConsentManagerProps) => {
  const {
    consent_non_essential_content,
    consent_preferences_dialog_title,
    consent_preferences_dialog_content,
  } = content;

  const { destinations, preferences, saveConsent, closeModal } = useConsentManager();

  return (
    <div data-testid="segment-consent-builder">
      <Styled.HeaderSection>
        <h4>{consent_preferences_dialog_title}</h4>
      </Styled.HeaderSection>
      <Styled.ConsentContent>
        <section>
          <Styled.CTAContainer>
            <ConsentForm
              initialValues={{ "Non-Essential": preferences.customPreferences["Non-Essential"] }}
              handleSubmit={(result: CategoryPreferences) => {
                saveConsent({ ...preferences.customPreferences, ...result }, true);
              }}
              handleCancel={closeModal}
            />
            <div data-testid="destination-list">
              <h5>Tools</h5>
              <Styled.ToolsList>
                {destinations.map((destination) => (
                  <li key={destination.id}>{destination.name}</li>
                ))}
              </Styled.ToolsList>
            </div>
          </Styled.CTAContainer>
        </section>
        <section data-testid="privacy-legal-content">
          <div>
            <PortableText value={consent_preferences_dialog_content} />
          </div>
          <div>
            <p>{consent_non_essential_content}</p>
          </div>
        </section>
      </Styled.ConsentContent>
    </div>
  );
};

export default SegmentConsentManager;
