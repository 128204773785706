import { useState } from "react";
import { Shopify } from "@models/shopify.model";
import { haveQuestionsContactText } from "@utils/siteText";
import * as Styled from "../cart-item/CartItem.styled";
import TextModal from "@components/modals/TextModal";

export interface CartItemMembershipProps {
  membership: Shopify.Product;
  lineItem: Shopify.LineItem;
}

const CartItemMembership = ({ membership, lineItem }: CartItemMembershipProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { description } = membership;
  const { title, variant } = lineItem;
  const { image } = variant;
  const quantity = 1;

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <Styled.Litem id="membership" data-testid='cart-item'>
      <Styled.LitemImage>
        {image?.src && (
          <figure>
            <img src={`${image.src}?width=320&height=320&crop=center`} alt={title} />
          </figure>
        )}
      </Styled.LitemImage>
      <Styled.LitemInner>
        <Styled.LitemInfo>
          <Styled.LitemName>
            {title} ({quantity})
            <Styled.LitemMoreInfo onClick={toggleModal} />
            <TextModal
              toggleModal={toggleModal}
              mounted={isModalOpen}
              cmsNode={{
                title,
                text: description,
                smallText: haveQuestionsContactText(),
              }}
            />
          </Styled.LitemName>
          {description && <Styled.LitemDescription>{description}</Styled.LitemDescription>}
        </Styled.LitemInfo>

        <Styled.LitemQty>
          <Styled.LitemQtyLabel immutable={false} as="span">
            Qty:&nbsp; {quantity}
          </Styled.LitemQtyLabel>
        </Styled.LitemQty>
      </Styled.LitemInner>
    </Styled.Litem>
  );
};

export default CartItemMembership;
