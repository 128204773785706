import * as React from "react";
import { CloudinaryAsset } from "@models/cloudinary-asset.model";
import Image from "@components/primitives/image";

type CloudinaryFormatProps = {
  media: CloudinaryAsset;
  mediaRef?: React.MutableRefObject<HTMLVideoElement | HTMLImageElement | undefined>;
  widthOnScreenValues?: number | Array<number | null>;
};

enum MediaFormat {
  IMAGE = "image",
  VIDEO = "video",
}

const identifyFormat = (format: string): MediaFormat | null => {
  const imageTypes = ["jpg", "webp", "png"];
  const videoTypes = ["mp4", "webm"];

  if (imageTypes.indexOf(format) !== -1) {
    return MediaFormat.IMAGE;
  }

  if (videoTypes.indexOf(format) !== -1) {
    return MediaFormat.VIDEO;
  }

  return null;
};

const CloudinaryFormat = ({ media, mediaRef, widthOnScreenValues }: CloudinaryFormatProps) => {
  const format = identifyFormat(media?.format);

  if (format === MediaFormat.IMAGE) {
    return (
      <Image
        className="cloudinary-format"
        asset={media}
        alt={media?.alt}
        widthOnScreen={widthOnScreenValues}
        ref={mediaRef}
      />
    );
  } else if (format === MediaFormat.VIDEO) {
    return (
      <video
        className="cloudinary-format"
        src={media.url}
        loop
        autoPlay
        muted
        playsInline
        disablePictureInPicture
        ref={mediaRef as React.MutableRefObject<HTMLVideoElement>}
      />
    );
  }

  return null;
};

export default CloudinaryFormat;
