/**
 * Callback will rerender the Affirm Promotional Messaging component.
 * Needs to be called when needed. Will not update on prop change.
 */
const refreshUI = () => {
  const affirmJS = window.affirm;
  affirmJS && affirmJS.ui.ready(() => affirmJS.ui.refresh());
};

const AffirmCallbacks = {
  refreshUI,
};

export default AffirmCallbacks;
