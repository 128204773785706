import { Shopify } from "@models/shopify.model";
import { useShopifyCart } from "@providers/cart";
import * as Styled from "./Coupon.styled";

export interface CouponProps {
  showRemove: boolean;
  application: Shopify.DiscountApplication;
}

const Coupon = ({ application, showRemove }: CouponProps) => {
  const { checkout, removeCode } = useShopifyCart();

  return (
    <Styled.Coupon>
      {/* Workaround for safari bug */}
      <div className="clearfix">
        <div className={showRemove ? "code" : "code no-remove"}>
          {application.code}
          <button type="button" onClick={() => removeCode({ checkoutId: checkout.id })}>
            REMOVE
          </button>
        </div>
      </div>
    </Styled.Coupon>
  );
};

export default Coupon;
