import cn from "classnames";
import { useInView } from "react-intersection-observer";
import { urlJoin } from "url-join-ts";
import info from "@utils/info";
import { responsiveSizes } from "@utils/responsiveImageSizes";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";

interface Asset {
  asset?: string;
  width: number;
  height: number;
  url?: string;
}

// the default width of the image, a reasonable size for old browsers, mostly desktop, who don't support srcset
const defaultWidth = 1600;

// the default image in the src attribute, for backwards compatibility
const defaultImage = (uid: string, widthOnScreen: number) => {
  const adaptedWidth =
    widthOnScreen >= 100 ? defaultWidth : Math.round((defaultWidth / 100) * widthOnScreen);
  return assetUrl(uid, adaptedWidth);
};

const srcset = (uid: string, width: number, widthOnScreen: number) =>
  responsiveSizes({ sourceImageWidth: width, widthOnPage: widthOnScreen })
    .map((finalWidth) => `${assetUrl(uid, finalWidth)} ${finalWidth}w`)
    .join(", ");

// The Cloudinary asset delivery URL takes the following structure:
// https://res.cloudinary.com/<cloud_name>/<resource_type>/<type>/<transformations>/<version>/<public_id>.<format>
// https://cloudinary.com/documentation/image_transformation_reference
export const assetUrl = (uid: string, width: number | string) =>
  urlJoin(process.env.NEXT_PUBLIC_ASSET_BASE, `w_${width},c_scale,f_auto,q_auto`, uid);

const getDisplayWidth = (widthOnScreen: number | number[]) => {
  let ret = 100;
  if (Array.isArray(widthOnScreen)) {
    // find screen size
    const idx = info.isMobile() ? 0 : info.isTablet() ? 1 : 2;
    // go down screen size if it doesnt exist in widthOnScreen array
    for (let index = idx; index >= 0; index--) {
      if (widthOnScreen[index]) {
        ret = widthOnScreen[index];
        break;
      }
    }
  } else {
    ret = widthOnScreen;
  }
  return Math.min(ret, 100);
};

interface ImageProps {
  /** Image asset to display */
  asset: Asset;
  /**
   * The actual width of the image on the page, in vw (% of width), or an array
   * of sizes from [mobile, tablet, desktop] . A null breakpoint will keep the
   * same size as the previous,e.g. [100, null, 50] = 100% on mobile & tablet,
   * 50% on desktop
   */
  widthOnScreen: number | Array<number | null>;
  /** whether to eagerly or lazy load the image. defaults to lazy */
  loading?: "lazy" | "eager";
  style?: React.CSSProperties;
  className?: string;
  alt?: string;
  draggable?: boolean;
}

// NOTE This only works for cloudinary hosted images
const Image = forwardRef(
  (
    { asset, widthOnScreen, className, loading = "lazy", style, alt, draggable = true }: ImageProps,
    forwardedRef
  ) => {
    const { asset: uid, width, height, url } = asset;
    const displayWidth = useRef<number>(0);
    const lazy = loading === "lazy";
    const [ref, inView] = useInView({
      rootMargin: "200px 0px",
      triggerOnce: true,
    });

    const setRefs = useCallback(
      (node) => {
        if (typeof forwardedRef === "function") {
          forwardedRef(node);
        } else if (forwardedRef) {
          forwardedRef.current = node;
        }

        ref(node);
      },
      [ref]
    );

    const [attrs, setAttrs] = useState<{
      sizes: string;
      src: string;
      srcSet: string;
      width: number;
      height: number;
      draggable: boolean;
    }>();

    const generateAssetPath = (url: string) => {
      if (!url) return "";
      return `/ecomm-cms-assets/${url.split("/ecomm-cms-assets/")[1]}`;
    };

    useEffect(() => {
      displayWidth.current = getDisplayWidth(widthOnScreen);
    }, []);

    useEffect(() => {
      const src = defaultImage(uid ? uid : generateAssetPath(url), displayWidth.current);
      const srcSet = srcset(uid ? uid : generateAssetPath(url), width, displayWidth.current);
      setAttrs({
        width,
        height,
        sizes: `${displayWidth.current}vw`,
        ...(lazy && !inView ? { src: "", srcSet: "" } : { src, srcSet }),
        draggable,
      });
      if (inView) setAttrs({ ...attrs, src, srcSet });
    }, [asset.asset, inView]);

    return <img className={cn(className)} style={style} ref={setRefs} {...attrs} alt={alt || ""} />;
  }
);

Image.displayName = "Image";

export default Image;
