const info = {
  /**
   * @return {?}
   */
  isDesktopHd: () => {
    return matchMedia("(orientation: landscape) and (min-width: 1440px)").matches;
  },
  /**
   * @return {?}
   */
  isDesktop: () => {
    return (
      matchMedia("(orientation: portrait) and (min-width: 801px)").matches ||
      matchMedia("(orientation: landscape) and (min-width: 1025px)").matches
    );
  },
  /**
   * @return {?}
   */
  isMobile: () => {
    return (
      matchMedia("(orientation: portrait) and (max-width: 599px)").matches ||
      matchMedia("(orientation: landscape) and (max-width: 959px)").matches
    );
  },
  /**
   * @return {?}
   */
  isTablet: () => {
    return (
      matchMedia("(orientation: portrait) and (min-width: 600px) and (max-width: 800px)").matches ||
      matchMedia("(orientation: landscape) and (min-width: 960px) and (max-width: 1024px)").matches
    );
  },
  /**
   * @return {?}
   */
  isTabletPortrait: () => {
    return matchMedia("(orientation: portrait) and (min-width: 600px) and (max-width: 800px)")
      .matches;
  },
  /**
   * @return {?}
   */
  isRetina: () => {
    return matchMedia(
      "(min--moz-device-pixel-ratio: 1.3),(-o-min-device-pixel-ratio: 2.6/2),(-webkit-min-device-pixel-ratio: 1.3),(min-device-pixel-ratio: 1.3),(min-resolution: 1.3dppx)"
    ).matches;
  },
  /**
   * @return {?}
   */
  getScreenDefinition: () => {
    return info.isRetina() ? "hd" : "sd";
  },
  /**
   * @return {?}
   */
  getPlatform: () => {
    return info.isMobile() ? "mobile" : "desktop";
  },
  /**
   * @return {?}
   */
  getRatio: () => {
    return 14.4;
  },
  /**
   * @param {number} var_args
   * @return {?}
   */
  toViewportUnit: (var_args: number) => {
    /** @type {number} */
    const t = window.innerWidth || document.documentElement.clientWidth;
    return 1440 > t
      ? Math.ceil((var_args * document.documentElement.clientWidth) / 100 / info.getRatio())
      : var_args;
  },

  isTouchSupported: () => {
    return window.navigator.maxTouchPoints || "ontouchstart" in document.createElement("div");
  },
};

export default info;
