import Head from "next/head";
import { Globals } from "@models/globals.model";
import Footer from "@components/footer";
import Header from "@components/header";
import CartOverlay from "@components/cart-overlay";
import { Sanity } from "@models/sanity.model";
import { useRouter } from "next/router";
import { CANADA_SITE, US_SITE } from "@utils/constants";
import ConsentBanner from "@components/consent-manager-modal/ConsentBanner/ConsentBanner";
import { useConsentManager } from "@providers/consent/ConsentManagerProvider";

interface LayoutProps {
  globals: Globals;
  children: React.ReactNode;
  seo: Sanity.Seo | null;
  pageID?: string;
  hideBanner?: boolean;
  title?: string;
  transparentHeader?: boolean;
}

const Layout = ({
  globals,
  children,
  pageID,
  hideBanner,
  seo,
  title,
  transparentHeader,
}: LayoutProps) => {
  const router = useRouter();
  const { navigation, footer } = globals;
  const countryDomain = process.env.NEXT_PUBLIC_COUNTRY_CODE === "CA" ? CANADA_SITE : US_SITE;
  const canonicalURL = `${countryDomain}${router.asPath}`;
  const defaultDescription = "The Nearly Invisible Interactive Home Gym";
  const { showBanner } = useConsentManager();

  return (
    <>
      <Head>
        <title>{seo?.title ?? title}</title>
        {seo !== null && (
          <>
            <meta name="description" content={seo.description ?? defaultDescription} />
            {seo.keywords && <meta name="keywords" content={seo.keywords} />}
            <link rel="canonical" href={seo.canonical_url ?? canonicalURL} />

            {/* Open Graph */}
            <meta property="og:site_name" content={seo.og_site_name ?? "lululemon Studio"} />
            <meta property="og:title" content={seo.og_title ?? seo.title} />
            <meta
              property="og:description"
              content={seo.og_description ?? seo.description ?? defaultDescription}
            />
            <meta property="og:image:height" content="268" />
            <meta property="og:image:width" content="512" />
            <meta property="og:url" content={seo.canonical_url ?? canonicalURL} />
            <meta
              property="og:image"
              content={seo.og_image?.url ?? `https://www.mirror.co/og-image.jpg`}
            />
            <meta property="og:type" content="website" />

            <meta name="p:domain_verify" content="858c57a6ac5e0f6bfc3d7afe95a2d4af" />
            <meta property="itemprop:title" content={seo.itemprop_title ?? seo.title} />
            <meta
              property="itemprop:description"
              content={seo.itemprop_desc ?? defaultDescription}
            />
            {seo.no_index && <meta name="robots" content="noindex" />}

            <link rel="icon" href="/favicon.ico" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
            <meta name="msapplication-TileColor" content="#000000" />
            <meta name="theme-color" content="#000000" />
            <link rel="manifest" href="/site.webmanifest" />
            <script type="application/ld+json">
              {`
              {
                "@context": "https://schema.org",
                "@type": "Corporation",
                "name": "lululemon Studio",
                "url": "${countryDomain}",
                "logo": "https://res.cloudinary.com/themirror/image/upload/v1661441564/ecomm-cms-assets/production/icons/lululemonStudio_YogoStudio_Primary_Black_2.svg",
                "sameAs": [
                  "https://www.facebook.com/lululemonStudio/",
                  "https://twitter.com/lululemonStudio",
                  "https://www.instagram.com/lululemonstudio/",
                  "https://www.linkedin.com/company/lululemonstudio/",
                  "https://lululemonstudio.com"
                ]
              }
              `}
            </script>
          </>
        )}
      </Head>

      <Header sanity={navigation} hideBanner={hideBanner} transparent={transparentHeader} />
      <CartOverlay />

      <main id={pageID} className="scene">
        {children}
      </main>

      <Footer sanity={footer} />
      {showBanner && <ConsentBanner />}
    </>
  );
};

export default Layout;
